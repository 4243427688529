import React, { useEffect, useState } from "react";
import { LinearProgress, Typography } from "@mui/material";

const LinearProgressWithCountdown = ({ duration, text, callback, children }) => {
  const [secondsLeft, setSecondsLeft] = useState(duration / 1000);

  useEffect(() => {
    const interval = setInterval(() => {
      if (secondsLeft > 0) {
        setSecondsLeft((prevSeconds) => prevSeconds - 1);
      } else {
        clearInterval(interval);
        if (callback) {
          callback();
        }
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [secondsLeft, callback]);

  const progress = ((duration - secondsLeft) / duration) * 100;

  return (
    <div>
      {children}
      <LinearProgress variant="determinate" value={progress} />
      <Typography variant="body1">{text} ({secondsLeft})</Typography>
    </div>
  );
};

export default LinearProgressWithCountdown;
