import React, { useEffect, useState } from "react";
import { checkVersion } from "../services/apiService"; // Adjust the import path as needed
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import { useTranslation } from "react-i18next";
import { version_code } from "../version";

const CheckVersion = () => {
  const { t } = useTranslation();

  const [isNewVersion, setIsNewVersion] = useState(null);
  const [tooltipMessage, setTooltipMessage] = useState("");
  const [openTooltip, setOpenTooltip] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      checkVersion().then((response) => {
        if (response?.version?.data?.version_code) setIsNewVersion(response.version.data.version_code !== version_code);
        else setIsNewVersion(false);
      });
    }, 60 * 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (isNewVersion) setTooltipMessage(t("Új verzió elérhető, frissítse az oldalt dupla kattintással!"));
    else setTooltipMessage("");
  }, [isNewVersion, t]);

  const handleTooltipClose = () => {
    setOpenTooltip(false);
  };

  const handleTooltipOpen = () => {
    setOpenTooltip(true);

    setTimeout(() => {
      setOpenTooltip(false);
    }, 5000);
  };

  return (
    isNewVersion && (
      <Tooltip
        title={tooltipMessage}
        open={openTooltip}
        onClose={handleTooltipClose}
        disableHoverListener
        disableFocusListener
        disableTouchListener
      >
        <IconButton color="error"  onClick={handleTooltipOpen} onDoubleClick={() => window.location.reload()}>
          <NewReleasesIcon />
        </IconButton>
      </Tooltip>
    )
  );
};

export default CheckVersion;
