import React, { createContext, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { verifyToken } from "../services/apiService";
import { Modal, Box, Typography, Button } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const AuthContext = createContext({
  isAuthenticated: null,
  doVerification: () => {},
});

export function AuthProvider({ children }) {
  const { t } = useTranslation();

  const [isAuthenticated, setIsAuthenticated] = useState(null);

  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/login") {
      setIsAuthenticated(null);
    }
  }, []);

  const doVerification = () => {
    if (location.pathname === "/login") {
      setIsAuthenticated(null);
    } else {
      verifyToken()
        .then((response) => setIsAuthenticated(response))
        .catch(() => setIsAuthenticated(false));
    }
  };

  function SessionExpiredModal({ open, handleClose }) {
    const navigate = useNavigate();

    // Styles for the modal content
    const style = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 400,
      bgcolor: "background.paper",
      border: "2px solid #000",
      boxShadow: 24,
      p: 4,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    };

    return (
      <Modal
        open={open}
        aria-labelledby="session-expired-modal-title"
        aria-describedby="session-expired-modal-description"
      >
        <Box sx={style}>
          <Typography id="session-expired-modal-title" variant="h6" component="h2">
            {t("session-expired-title")}
          </Typography>
          <Typography id="session-expired-modal-description" sx={{ mt: 2 }}>
            {t("session-expired")}
          </Typography>
          <Button
            sx={{ mt: 2 }}
            variant="contained"
            onClick={() => {
              handleClose?.();
              navigate("/login");
            }}
          >
            OK
          </Button>
        </Box>
      </Modal>
    );
  }

  return (
    <AuthContext.Provider value={{ isAuthenticated, doVerification }}>
      <SessionExpiredModal
        open={isAuthenticated === false && location.pathname !== "/login"}
        handleClose={() => setIsAuthenticated(null)}
      />
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => useContext(AuthContext);
