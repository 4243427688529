// ContextProviders.js
import React from "react";
import { FingerprintProvider } from "./FingerprintContext.js";
import { HostProvider } from "./HostContext.js";
import { MessageProvider } from "./MessageContext.js";
import { AuthProvider } from "./AuthContext.js";

const ContextProviders = ({ children }) => (
  <FingerprintProvider>
    <HostProvider>
      <AuthProvider>
        <MessageProvider>{children}</MessageProvider>
      </AuthProvider>
    </HostProvider>
  </FingerprintProvider>
);

export default ContextProviders;
