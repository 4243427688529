import React from "react";
import Backdrop from "@mui/material/Backdrop";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import Button from "@mui/material/Button";

function Denied({ open, doClose, buttonText }) {
  return (
    open && (
      <div style={{ position: "fixed", top: 0, left: 0, width: "100vw", height: "100vh", zIndex: 1300 }}>
        <Backdrop
          onClick={doClose}
          open={open}
          style={{
            color: "#fff",
            backgroundColor: "rgba(255, 0, 0, 0.7)", // Red background
            display: "flex", // Use flex layout to center the icon and button
            flexDirection: "column", // Stack items vertically
            alignItems: "center", // Center items horizontally
            justifyContent: "center", // Center items vertically
            width: "100%",
            height: "100%",
          }}
        >
          <DoNotDisturbOnIcon style={{ fontSize: "100px", color: "#fff" }} />
          {doClose && buttonText && (
            <Button
              variant="contained"
              onClick={doClose}
              sx={{
                backgroundColor: "grey",
                "&:hover": { backgroundColor: "darkgrey" },
              }}
              style={{ marginTop: "20px", fontWeight: "bold", width: "25%", padding: "10px" }}
            >
              {buttonText}
            </Button>
          )}
        </Backdrop>
      </div>
    )
  );
}

export default Denied;
