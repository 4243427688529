import React from "react";
import { Grid, Typography, Link, Box } from "@mui/material";
import LanguageSwitcher from "./LanguageSwitcher"; // Assuming you have this component
import { isZeroHost } from "../functions";

const Footer = () => {
  return (
    <Box
      sx={{
        width: "100%",
        borderTop: "1px solid #ccc",
      }}
    >
      <Grid container maxWidth={"xl"} margin={"auto"} alignItems={"center"} justifyContent={"space-between"}>
        <Grid item xs={12} sm={4}>
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{
              textAlign: { xs: "center", sm: "left" }, // Center text on xs screens, left-align on sm screens and up
            }}
          >
            © 2023-{new Date().getFullYear()} Powered by
            {isZeroHost() ? (
              <> ATN Global IT</>
            ) : (
              <Link
                href="https://www.atn.hu"
                color="inherit"
                underline="hover"
                target="_blank"
                rel="noopener noreferrer"
                sx={{ ml: 1 }}
              >
                ATN Global IT
              </Link>
            )}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{
              textAlign: "right",
            }}
          >
            
            {!isZeroHost() && <LanguageSwitcher />}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
