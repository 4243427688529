import React, { useEffect, useRef } from "react";
import { IconButton, Menu, MenuItem, Divider, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth } from "../context/AuthContext";
import { useHost } from "../context/HostContext";

const AppMenu = ({ onLogout, isMenuOpen, onMenuItemClick }) => {
  const { t } = useTranslation();

  const { host } = useHost();
  const { doVerification } = useAuth();

  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuButtonRef = useRef(null); // Létrehozunk egy ref-et az IconButton számára
  const open = Boolean(anchorEl);

  //const [registrationMenuAnchorEl, setRegistrationMenuAnchorEl] = useState(null);
  //const registrationMenuOpen = Boolean(registrationMenuAnchorEl);

  useEffect(() => {
    if (isMenuOpen && !anchorEl) {
      setAnchorEl(menuButtonRef.current);
    } else if (!isMenuOpen && anchorEl) {
      //TODO
    }
  }, [isMenuOpen, anchorEl]);

  const handleClose = () => {
    setAnchorEl(null);
    //setRegistrationMenuAnchorEl(null);
  };

  const handleMenuItemClick = (menuItem) => {
    doVerification();

    if (menuItem === "logout") {
      if (typeof onLogout === "function") {
        onLogout?.();
      } else {
        localStorage.removeItem("authToken");

        navigate("/login");
      }
    }

    onMenuItemClick?.(menuItem);

    handleClose();
  };
  /*
  const handleRegistrationMenuOpen = (event) => {
    setRegistrationMenuAnchorEl(event.currentTarget);
  };
*/
  return (
    <div>
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label="menu"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={() => setAnchorEl(localStorage.getItem("zeroHost") ? null : menuButtonRef.current)}
        ref={menuButtonRef} // Hozzárendeljük a ref-et az IconButton-hoz
        sx={{ visibility: localStorage.getItem("zeroHost") ? "hidden" : "" }}
      >
        <MenuIcon />
      </IconButton>
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
        {host?.roles?.includes("host")
          ? [
              <MenuItem key="host" disabled>
                <Typography variant="caption" style={{ fontSize: "smaller", fontVariant: "small-caps" }}>
                  {t("host")}
                </Typography>
              </MenuItem>,

              <MenuItem key="resource-location" onClick={() => handleMenuItemClick("resource-location")}>
                {t("menu-set-location")}
              </MenuItem>,
              <MenuItem key="resource-usage" onClick={() => handleMenuItemClick("resource-usage")}>
                {t("menu-admission")}
              </MenuItem>,
              <MenuItem key="profile" onClick={() => handleMenuItemClick("profile")}>
                {t("menu-profile")}
              </MenuItem>,
              <Divider key="divider" />,
            ]
          : []}

        {/* 
        <MenuItem onClick={handleRegistrationMenuOpen}>{t("menu-registration")}</MenuItem>
        <Menu
          id="registration-submenu"
          anchorEl={registrationMenuAnchorEl}
          keepMounted
          open={registrationMenuOpen}
          onClose={handleClose}
          style={{ marginLeft: "20px" }}
        ></Menu>
 */}

        {host?.roles?.includes("register")
          ? [
              <MenuItem key="register" disabled>
                <Typography variant="caption" style={{ fontSize: "smaller", fontVariant: "small-caps" }}>
                  {t("register")}
                </Typography>
              </MenuItem>,

              <MenuItem key="registration-create" onClick={() => handleMenuItemClick("registration-create")}>
                {t("menu-registration-create")}
              </MenuItem>,

              <MenuItem key="registration-search" onClick={() => handleMenuItemClick("registration-search")}>
                {t("menu-registration-search")}
              </MenuItem>,

              <MenuItem key="resource-modify" onClick={() => handleMenuItemClick("resource-modify")}>
                {t("menu-registration-resource-modify")}
              </MenuItem>,

              <Divider key="divider-1" />,

              <MenuItem
                key="registration.printer-setip"
                onClick={() => handleMenuItemClick("registration-printer-setup")}
              >
                {t("menu-registration-printer-setup")}
              </MenuItem>,

              <Divider key="divider-2" />,
            ]
          : []}
        {host?.roles?.includes("manager")
          ? [
              <MenuItem key="manager" disabled>
                <Typography variant="caption" style={{ fontSize: "smaller", fontVariant: "small-caps" }}>
                  {t("manager")}
                </Typography>
              </MenuItem>,

              <MenuItem key="manager-settings" onClick={() => handleMenuItemClick("manager-settings")}>
                {t("menu-manager-settings")}
              </MenuItem>,

              <MenuItem key="manager-search" onClick={() => handleMenuItemClick("manager-search")}>
                {t("menu-manager-search")}
              </MenuItem>,

              <MenuItem key="import-table" onClick={() => handleMenuItemClick("import-table")}>
                {t("menu-import-table")}
              </MenuItem>,

              <MenuItem key="user-monitor" onClick={() => handleMenuItemClick("user-monitor")}>
                {t("menu-user-monitor")}
              </MenuItem>,

              <MenuItem key="transaction-monitor" onClick={() => handleMenuItemClick("transaction-monitor")}>
                {t("menu-transaction-monitor")}
              </MenuItem>,

              <MenuItem key="transaction-search" onClick={() => handleMenuItemClick("transaction-search")}>
                {t("menu-transaction-search")}
              </MenuItem>,

              <Divider key="divider-1" />,
            ]
          : []}

        <MenuItem onClick={() => handleMenuItemClick("logout")}>{t("logout")}</MenuItem>
      </Menu>
    </div>
  );
};

export default AppMenu;
