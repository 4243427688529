import React from 'react';
import { Fab, Zoom, useScrollTrigger } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const ScrollToTop = ({ getWindow }) => {
  // This makes it clear that we expect a function that returns a window-like object
  const trigger = useScrollTrigger({
    target: getWindow ? getWindow() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = () => {
    // Here we directly use the global window object, as this function will only be called in a browser environment
    if (typeof window !== 'undefined') {
      const anchor = document.querySelector('#back-to-top-anchor');
      
      if (anchor) {
        anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
      } else {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" style={{ position: 'fixed', bottom: 16, right: 16 }}>
        <Fab color="secondary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </div>
    </Zoom>
  );
};

export default ScrollToTop;
