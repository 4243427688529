import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFingerprint } from "../../context/FingerprintContext";
import { useHost } from "../../context/HostContext";
import { Button, TextField, Container, Typography, Box, FormControl, Grid } from "@mui/material";
import Alert from "@mui/material/Alert";
import QRScanner from "../qrscanner/QRScanner";
import MultilineText from "../../helpers/MultilineText";
import { login } from "../../services/apiService";
import { useMessage } from "../../context/MessageContext";
import { useTranslation } from "react-i18next";
import Loading from "../../components/Loading";

function Login() {
  const { t } = useTranslation();

  const { messageText, messageType, setMessage } = useMessage();

  const fingerprint = useFingerprint();

  const { host, updateHost } = useHost();

  const [loading, setLoading] = useState(false);

  const [password, setPassword] = useState("");

  const [loginId, setLoginId] = useState("");

  const [qrCode, setQrCode] = useState("");

  const navigate = useNavigate();

  const [showPassordField, setShowPasswordField] = useState(true);

  const [showScanner, setShowScanner] = useState(false);
  const [showLoginId, setShowLoginId] = useState(false);

  useEffect(() => {
    updateHost();

    setMessage("");
  }, []);

  useEffect(() => {
    if (host) navigate("/home");
  }, [host]);

  useEffect(() => {
    if (qrCode) handleLogin();
  }, [qrCode]);

  const storeTokenAsync = (token) => {
    return new Promise((resolve, reject) => {
      try {
        localStorage.setItem("authToken", token);

        resolve(true); // The token is successfully verified
      } catch (error) {
        reject(error);
      }
    });
  };

  const handleResult = (result) => {
    if (result) {
      setLoading(true);
      setQrCode(result);
    }
  };

  const handleError = (err) => {
    setMessage({ text: err, type: "error" });
  };

  const handleLogin = async () => {
    setMessage("");

    try {
      setQrCode(null);

      const response = await login(loginId, qrCode, password, fingerprint);

      if (response?.success) {
        await storeTokenAsync(response.token);

        updateHost();

        navigate("/home");
      } else {
        localStorage.removeItem("authToken");

        setMessage({ text: `${t("error.loginFailed")} (${response.message})`, type: "error" });
      }
    } catch (error) {
      localStorage.removeItem("authToken");

      setMessage(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container component="main">
      <Box
        maxWidth="xs"
        sx={{
          marginTop: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5" sx={{ mb: 1 }}>
          {t("login")}
        </Typography>

        {messageText && (
          <Alert sx={{ mb: 1 }} severity={messageType}>
            {messageText}
          </Alert>
        )}

        <Box component="form" onSubmit={(event) => event.preventDefault()} noValidate>
          {loading && <Loading />}

          {!loading && (
            <>
              {showPassordField && (
                <FormControl fullWidth>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label={t("password")}
                    type="password"
                    autoComplete="off"
                    autoFocus
                    value={password}
                    onBlur={() => setMessage("")}
                    onChange={(e) => {
                      setPassword(e.target.value);

                      setMessage("");
                    }}
                  />
                  <Grid
                    container
                    justifyContent={"center"}
                    justifyItems={"center"}
                    alignItems={"center"}
                    alignContent={"center"}
                    gap={1}
                    sx={{ mt: 1 }}
                  >
                    <Grid item xs={12} md={5}>
                      <Button
                        type="button"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 1, mb: 2 }}
                        onClick={(e) => {
                          e.preventDefault();

                          if (password) {
                            setShowPasswordField(false);
                            setShowScanner(true);

                            setLoginId("");
                          } else {
                            setMessage({ text: t("error.password-required"), type: "error" });
                          }
                        }}
                      >
                        {t("login-with-qrcode")}
                      </Button>
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <Button
                        type="button"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 1, mb: 2 }}
                        onClick={(e) => {
                          e.preventDefault();

                          if (password) {
                            setShowPasswordField(false);
                            setShowLoginId(true);

                            setQrCode(null);
                          } else {
                            setMessage({ text: t("error.password-required"), type: "error" });
                          }
                        }}
                      >
                        {t("login-with-loginid")}
                      </Button>
                    </Grid>
                  </Grid>
                </FormControl>
              )}

              {(showLoginId || showScanner) && (
                <Button
                  xs={12}
                  md={6}
                  type="button"
                  onClick={() => {
                    setMessage("");

                    setShowPasswordField(true);
                    setShowScanner(false);
                    setShowLoginId(false);
                  }}
                  fullWidth
                  variant="contained"
                  sx={{ mt: 1, mb: 2 }}
                >
                  {t("modify-password")}
                </Button>
              )}

              {showLoginId && (
                <FormControl fullWidth>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label={t("login-id")}
                    type="text"
                    autoComplete="off"
                    autoFocus
                    value={loginId}
                    onBlur={() => setMessage("")}
                    onChange={(e) => {
                      setLoginId(e.target.value);

                      setMessage("");
                    }}
                  />

                  <Button
                    type="button"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 1, mb: 2 }}
                    onClick={(e) => {
                      e.preventDefault();

                      if (!password) {
                        setMessage({ text: t("error.password-required"), type: "error" });
                        return;
                      }

                      if (!loginId) {
                        setMessage({ text: t("error.login-required"), type: "error" });
                        return;
                      }

                      setLoading(true);

                      handleLogin();
                    }}
                  >
                    OK
                  </Button>
                </FormControl>
              )}

              {showScanner && (
                <Box>
                  <MultilineText text={t("login-scan-title")} />

                  <QRScanner setResult={handleResult} handleError={handleError} />
                </Box>
              )}
            </>
          )}
        </Box>
      </Box>
    </Container>
  );
}

export default Login;
