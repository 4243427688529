import React, { useState } from "react";
import { Button, MenuItem, Select, FormControl, InputLabel, Box } from "@mui/material";
import { setUserResourceQuantity, createUser } from "../services/apiService";
import { useHost } from "../context/HostContext";

function processData(mappings, data) {
  const result = { user: {}, resources: [] };

  // Iterate through each entry in the data object
  for (const [key, value] of Object.entries(data)) {
    // Check if the key maps to a user attribute
    if (mappings[key].startsWith("user:")) {
      const userField = mappings[key].split(": ")[1];
      result.user[userField] = value;
    } else if (mappings[key].startsWith("resource")) {
      // Parse the resource ID from the mapping
      const resourceId = parseInt(mappings[key].split("_")[1].split(": ")[0]);
      result.resources.push({ resource_id: resourceId, resource_quantity: value });
    }
  }

  // Filter out resources with quantity 0
  result.resources = result.resources.filter((resource) => resource.resource_quantity > 0);

  return result;
}

const DataTableImport = ({ fields, columns, data }) => {
  const { host } = useHost();

  // State to hold the mappings
  // This will be an object where the key is the column name and the value is the field name
  const [mappings, setMappings] = useState({});

  // Function to handle changing the mapping for a column
  const handleMappingChange = (column, field) => {
    setMappings((prev) => ({
      ...prev,
      [column]: field,
    }));
  };

  // Function to handle the import data action
  const handleImportData = () => {
    // Here you would take the mappings and the dataTable to process the data
    // This processing will depend on your specific requirements
    //    console.log("Mappings:", mappings);
    //  console.log("Data Table:", data);
    // Process data...

    data.map((data) => {
      const mappedData = processData(mappings, data);

      createUser({
        title: mappedData.user.user_title || "",
        first_name: mappedData.user.user_first_name,
        last_name: mappedData.user.user_last_name,
        name: `${mappedData.user.user_title || ""} ${mappedData.user.user_last_name} ${
          mappedData.user.user_first_name
        } `.trim(),
        roletype: "attendee",
        enabled: true,
      })
        .then((response) => {
          mappedData.resources.map((resource) => {
            setUserResourceQuantity(
              host.id,
              response.user_id,
              resource.resource_id,
              resource.resource_quantity,
              "import",
              1
            )
              .then()
              .catch((error) => console.log(error));
          });
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

return (
    <Box sx={{ maxWidth: 500, margin: "auto" }}>
      {columns.map((column, index) => (
        <FormControl fullWidth key={index} margin="normal">
          <InputLabel>{column.name}</InputLabel>
          <Select
            value={mappings[column.name] || ""}
            label={column.name}
            onChange={(e) => handleMappingChange(column.name, e.target.value)}
          >
            {fields.map((field, fieldIndex) => (
              <MenuItem key={fieldIndex} value={field}>
                {field}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ))}
      <Button variant="contained" onClick={handleImportData} sx={{ marginTop: 2 }}>
        {`Import data (${data?.length || 0})`}
      </Button>
    </Box>
  );
};

export default DataTableImport;
