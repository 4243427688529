import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DataTable from "react-data-table-component";
import { Box, IconButton } from "@mui/material";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import LinearProgress from "@mui/material/LinearProgress";
import moment from "moment";
import "moment/locale/hu";
import { searchUser, searchTransaction } from "../../services/apiService";
import { useSSE } from "../../services/sseService";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import { QrCode } from "@mui/icons-material";

const sortIcon = <ArrowDownward />;

const UpdateMonitor = ({ tableName }) => {
  const { t } = useTranslation();

  const [data, setData] = useState([]);

  const [isLoading, setIsLoading] = useState(null);

  const [dataChanged, setDataChanged] = useState(null);

  useSSE(setDataChanged, `/table/${tableName}`);

  const columnsDef = {
    users: [
      {
        id: "updatedAt",
        name: "Módosítva",
        sortable: true,
        field: "updatedAt",
        selector: (row) => (row["updatedAt"] ? moment(row["updatedAt"]).locale("hu").format("L HH:mm:ss:SSS") : ""),
      },
      {
        id: "user_name",
        name: "Név",
        sortable: true,
        field: "user_name",
        selector: (row) => row["user_name"],
      },
      {
        id: "user_enabled",
        name: "Engedélyezett",
        sortable: true,
        field: "user_enabled",
        format: (row) => (
          <IconButton>
            {row["user_enabled"] ? (
              <CheckCircleOutlineIcon color={"success"} />
            ) : (
              <DoNotDisturbOnIcon color={"error"} />
            )}
          </IconButton>
        ),
        selector: (row) => row["user_enabled"],
        $center: "true",
        $compact: "true",
      },
      {
        id: "user_qrcode_printed",
        name: "QR-kód nyomtatva",
        sortable: true,

        field: "user_qrcode_printed",
        selector: (row) => (
          <>
            <IconButton sx={{ paddingRight: 0, paddingLeft: 0 }}>
              <QrCode />
            </IconButton>
            {row["user_qrcode_printed"] ? moment(row["user_qrcode_printed"]).locale("hu").format("lll") : ""}
          </>
        ),
      },
      {
        id: "createdAt",
        name: "Regisztráció",
        sortable: true,
        field: "createdAt",
        selector: (row) => (row["createdAt"] ? moment(row["createdAt"]).locale("hu").format("lll") : ""),
      },
    ],
    resource_transactions: [
      {
        id: "updatedAt",
        name: "Módosítva",
        sortable: true,
        field: "updatedAt",
        selector: (row) => (row["updatedAt"] ? moment(row["updatedAt"]).locale("hu").format("L HH:mm:ss:SSS") : ""),
      },
      {
        id: "user_name",
        name: "Név",
        sortable: true,
        field: "user_name",
        selector: (row) => row["User"]["user_name"],
      },
      {
        id: "resource_name",
        name: "Erőforrás",
        sortable: true,
        field: "resource_name",
        selector: (row) => row["Resource"]["resource_name"],
      },
      {
        id: "resource_transaction_quantity",
        name: "Mennyiség változás",
        field: "resource_transaction_quantity",
        selector: (row) => row["resource_transaction_quantity"],
      },
      {
        id: "resource_transaction_description",
        name: "Megjegyzés",
        field: "resource_transaction_description",
        selector: (row) => row["resource_transaction_description"],
      },
      {
        id: "handler_name",
        name: "Rendezvénykoordinátor",
        sortable: true,
        field: "handler_name",
        selector: (row) => row["Handler"]["user_name"],
      },
    ],
  };

  useEffect(() => {
    if (dataChanged && dataChanged.length > 0) {
      dataChanged.forEach((data) => fetchData(data.id || 0));
    }
  }, [dataChanged]);

  function fetchData(id) {
    if (id) {
      setIsLoading(true);

      switch (tableName) {
        case "users":
          searchUser({ id }, { resourcedata: false, transactiondata: false, handlertransactiondata: false })
            .then((response) => {
              setData((currentData) => [...currentData, ...response]);
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => setIsLoading(false));
          break;

        case "resource_transactions":
          searchTransaction({ id }, { resourcedata: true, userdata: true, handlerdata: true })
            .then((response) => {
              console.log(response);
              setData((currentData) => [...currentData, ...response]);
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => setIsLoading(false));
          break;

        default:
          break;
      }
    }
  }

  const LinearIndeterminate = () => {
    return (
      <Box
        sx={{
          width: "100%",
          "& > * + *": {
            marginTop: 2,
          },
        }}
      >
        <LinearProgress />
      </Box>
    );
  };

  return (
    <>
      <DataTable
        columns={columnsDef[tableName]}
        data={data}
        progressPending={isLoading}
        progressComponent={<LinearIndeterminate />}
        sortIcon={sortIcon}
        defaultSortFieldId={"updatedAt"}
        defaultSortAsc={false}
        noDataComponent={t("nodata")}
      />
    </>
  );
};

export default UpdateMonitor;
