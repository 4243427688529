import React, { useState } from "react";
import { Button, TextField, Container, Typography, Grid, CardActions, CardHeader, Card } from "@mui/material";
import { useTranslation } from "react-i18next";
import { createUser, updateUser } from "../../services/apiService";
import ProfileData from "../profile/ProfileData";
import { doPrint } from "../../functions";
import { useHost } from "../../context/HostContext";
import { QrCode } from "@mui/icons-material";

const CreateUserForm = () => {
  const initialFormData = {
    userName: "",
    userQrCode: "",
    userEmail: "",
    userCompany: "",
    userLogin: "",
    userPassword: "",
    userTitle: "",
    userFirstName: "",
    userLastName: "",
  };

  const [formData, setFormData] = useState(initialFormData);

  const { t } = useTranslation();

  const { host } = useHost();

  const [isPrinting, setIsPrinting] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    createUser({
      title: formData.userTitle,
      first_name: formData.userFirstName,
      last_name: formData.userLastName,
      name: `${formData.userTitle || ""} ${formData.userLastName} ${formData.userFirstName}`.trim(),
      company: formData.userCompany,
      email: formData.userEmail,
      roletype: "attendee",
      enabled: true,
    })
      .then((response) => {
        setFormData({
          // ...formData,
          ["userId"]: response.user_id,
          ["userName"]: response.user_name,
          ["userQrCode"]: response.user_qrcode,
          ["userEmail"]: response.user_email,
          ["userCompany"]: response.user_company,
          ["userTitle"]: response.user_title,
          ["userFirstName"]: response.user_first_name,
          ["userLastName"]: response.user_last_name,
        });
      })
      .catch((error) => {
        if (error?.status === 403) {
          //TODO
        }
      });
    //  .finally(() => setIsProcessing(false));
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" component="h1" gutterBottom>
        {t("register-attendee")}
        {formData?.userId && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => setFormData(initialFormData)}
            style={{ marginLeft: 20 }}
          >
            {t("new-attendee")}
          </Button>
        )}
      </Typography>

      {!formData.userId && (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={t("attendee-title")}
                name="userTitle"
                value={formData.userTitle}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label={t("attendee-last-name")}
                name="userLastName"
                value={formData.userLastName}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label={t("attendee-first-name")}
                name="userFirstName"
                value={formData.userFirstName}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12}>
              <Button type="submit" fullWidth variant="contained">
                {t("submit")}
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
      {formData?.userId && (
        <>
          <Card>
            <CardHeader
              title={formData?.userName}
              titleTypographyProps={{ align: "center" }}
              subheader={t("qrcode-printing")}
              subheaderTypographyProps={{ align: "center", color: "white" }}
              sx={{
                backgroundColor: "secondary.main",
                color: "secondary.contrastText",
              }}
            />

            <CardActions sx={{ justifyContent: "center", flexWrap: "wrap", gap: "20px" }}>
              <Button
                variant="contained"
                startIcon={<QrCode className={isPrinting ? "flip-horizontal" : ""} />}
                onClick={() => {
                  setIsPrinting(true);
                  doPrint(formData.userQrCode, formData.userFirstName,formData.userLastName, undefined, {...host?.event_config})
                    .then(() => {
                      return updateUser(host.id, formData?.userId, { user_qrcode_printed: new Date() });
                    })
                    .finally(() => setIsPrinting(false));
                }}
                disabled={isPrinting}
                style={{ margin: 10 }}
              >
                {t("button-print")}
              </Button>
            </CardActions>
          </Card>

          <ProfileData id={formData?.userId} showOnly={true} isEditAllowed={true} allResources={true}></ProfileData>
        </>
      )}
    </Container>
  );
};

export default CreateUserForm;
