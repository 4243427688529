import React, { useState, useEffect } from "react";
import { Button, TextField, Typography, Container, Grid, Paper } from "@mui/material";

const EventSettings = () => {
  const [eventData, setEventData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchEventData();
  }, []);

  const fetchEventData = async () => {
    try {
      setEventData(null);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching event data:", error);
      setIsLoading(false);
    }
  };

  const handleSave = async () => {
    try {
      console.log("Event data updated successfully!");
    } catch (error) {
      console.error("Error updating event data:", error);
    }
  };

  if (isLoading) return <Typography>Loading...</Typography>;

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" gutterBottom>
        Event Settings
      </Typography>
      {eventData && (
        <form>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                label="Event Name"
                fullWidth
                value={eventData.event_name}
                InputLabelProps={{ shrink: true }}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Event QR Code"
                fullWidth
                value={eventData.event_qrcode || ""}
                InputLabelProps={{ shrink: true }}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Event Start"
                fullWidth
                value={eventData.event_start}
                InputLabelProps={{ shrink: true }}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Event End"
                fullWidth
                value={eventData.event_end}
                InputLabelProps={{ shrink: true }}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Event Location"
                fullWidth
                value={eventData.event_location}
                InputLabelProps={{ shrink: true }}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Event URL"
                fullWidth
                value={eventData.event_url}
                InputLabelProps={{ shrink: true }}
                margin="normal"
              />
            </Grid>

            <Paper elevation={3} style={{ padding: "20px", marginTop: "20px" }}>
              <Typography variant="h6" gutterBottom>
                Event Config
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    label="Resource Use Timeout Allowed"
                    fullWidth
                    value={eventData.event_config.resource_use_timeout_allowed.toString()}
                    InputLabelProps={{ shrink: true }}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Resource Use Timeout Denied"
                    fullWidth
                    value={eventData.event_config.resource_use_timeout_denied.toString()}
                    InputLabelProps={{ shrink: true }}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Attendee QR-code prefix"
                    fullWidth
                    value={eventData.event_config.attendee_qrcode_prefix.toString()}
                    InputLabelProps={{ shrink: true }}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Attendee QR-code suffix"
                    fullWidth
                    value={eventData.event_config.attendee_qrcode_suffix.toString()}
                    InputLabelProps={{ shrink: true }}
                    margin="normal"
                  />
                </Grid>
              </Grid>
            </Paper>

            <Button variant="contained" color="primary" onClick={handleSave}>
              Save
            </Button>
          </Grid>
        </form>
      )}
    </Container>
  );
};

export default EventSettings;
