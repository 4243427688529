// FingerprintContext.js
import React, { createContext, useState, useEffect, useContext } from 'react';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

const FingerprintContext = createContext();

export const FingerprintProvider = ({ children }) => {
  const [fingerprint, setFingerprint] = useState('');

  useEffect(() => {
    const getFingerprint = async () => {
      const fp = await FingerprintJS.load();
      const result = await fp.get();
      setFingerprint(result.visitorId);
    };

    getFingerprint();
  }, []);

  return (
    <FingerprintContext.Provider value={fingerprint}>
      {children}
    </FingerprintContext.Provider>
  );
};

// Hook a könnyű hozzáférésért
export const useFingerprint = () => useContext(FingerprintContext);
