import React from 'react';
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  return (
    <>
      <IconButton
        onClick={() => changeLanguage('en')}
        aria-label="English"
        color={currentLanguage === 'en' ? 'primary' : 'default'}
      >
        🇬🇧
      </IconButton>
      <IconButton
        onClick={() => changeLanguage('hu')}
        aria-label="Hungarian"
        color={currentLanguage === 'hu' ? 'primary' : 'default'}
      >
        🇭🇺
      </IconButton>
    </>
  );
};

export default LanguageSwitcher;
