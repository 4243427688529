import React, { useEffect, useState } from "react";
import { checkBackendAvailability } from "../services/apiService"; // Adjust the import path as needed
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { Circle } from "@mui/icons-material";
import { green, red } from "@mui/material/colors";
import { useTranslation } from "react-i18next";

const CheckBackend = ({noClick}) => {
  const { t } = useTranslation();

  const [isBackendAvailable, setIsBackendAvailable] = useState(null);
  const [tooltipMessage, setTooltipMessage] = useState("");
  const [openTooltip, setOpenTooltip] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      checkBackendAvailability().then((response) => {
        const result = response.data;
        const now = new Date().toLocaleString();
        setIsBackendAvailable(result?.available);
        setTooltipMessage(
          <React.Fragment>
            {t(result?.available ? "server-available" : "server-unavailable")} ({result?.version_code})
            <br />
            {t("last-check-time", { now: now })}
          </React.Fragment>
        );
      });
    }, 10 * 1000);

    return () => clearInterval(intervalId);
  }, [t]); // Added 't' to useEffect dependencies to ensure correct language updates

  const handleTooltipClose = () => {
    setOpenTooltip(false);
  };

  const handleTooltipOpen = () => {
    if (noClick) return;
    
    setOpenTooltip(true);

    setTimeout(() => {
      setOpenTooltip(false);
    }, 5000);
  };

  const circleStyle =
    isBackendAvailable === null
      ? { visibility: "hidden" }
      : isBackendAvailable
      ? { color: green[500] }
      : { color: red[500], animation: "pulsate 1.5s infinite" };

  return (
    <Tooltip
      title={tooltipMessage}
      open={openTooltip}
      onClose={handleTooltipClose}
      disableHoverListener
      disableFocusListener
      disableTouchListener
    >
      <IconButton onClick={handleTooltipOpen}>
        <Circle style={circleStyle} />
      </IconButton>
    </Tooltip>
  );
};

export default CheckBackend;
