import React from "react";
import Typography from '@mui/material/Typography';

function MultilineText({ text }) {
  const lines = text.split('\n');

  return (
    <Typography variant="h5" gutterBottom align="center" style={{ marginTop: '10px' }}>
      {lines.map((line, index) => (
        <React.Fragment key={index}>
          {line}
          {index < lines.length - 1 && <br />}
        </React.Fragment>
      ))}
    </Typography>
  );
}

export default MultilineText;