import { useEffect } from "react";
import { getURL } from "./apiService";

const useSSE = (setMessage, sseType) => {
  useEffect(() => {
    const token = localStorage.getItem("authToken");

    const url = getURL(`/sse${sseType}`) + `?token=${encodeURIComponent(token)}`;

    const eventSource = new EventSource(url);

    eventSource.onmessage = function (event) {
      const parsedData = JSON.parse(event.data);
      
      setMessage(parsedData);
    };

    eventSource.onerror = function (event) {
      console.error("EventSource failed:", event);
      eventSource.close();
    };

    return () => {
      eventSource.close();
    };
  }, [setMessage]);
};

export { useSSE };
