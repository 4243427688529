import React, { useMemo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DataTable from "react-data-table-component";
import {
  Box,
  Button,
  TextField,
  Grid,
  InputAdornment,
  Dialog,
  Card,
  CardHeader,
  CardContent,
  Switch,
  FormGroup,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import Delete from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import { doPrint, exportToXlsx } from "../../functions";
import LinearProgress from "@mui/material/LinearProgress";
import { Checkbox, FormControlLabel } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import moment from "moment";
import "moment/locale/hu";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import { useHost } from "../../context/HostContext";
import { searchUser, updateUser } from "../../services/apiService";
//import { useSSE } from "../../services/sseService";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import { Edit, QrCodeScanner, QrCode, FileDownload } from "@mui/icons-material";
import QRScanner from "../qrscanner/QRScanner";
import UserForm from "./UserForm";
import EditableQuantity from "../profile/EditableQuantity";
import QRCode from "react-qr-code";
import { useMessage } from "../../context/MessageContext";
import ProfileData from "../profile/ProfileData";

const collator = new Intl.Collator("en", { sensitivity: "base" });

const customSort = (rowA, rowB, field, sortDirection) => {
  const comparisonResult = collator.compare(rowA[field], rowB[field]);

  // If sortDirection is 'desc', reverse the comparison result
  return sortDirection === "desc" ? -comparisonResult : comparisonResult;
};

//const searchableColumns = columns.filter((column) => !column.omit).map((column) => column.field);
const searchableColumns = ["user_name"];

const sortIcon = <ArrowDownward />;

const SearchUserForm = ({ roletype }) => {
  const { t } = useTranslation();

  const { host } = useHost();

  const { setMessage } = useMessage();

  const [data, setData] = useState([]);

  const initialState = {
    user_enabled: "indeterminate", // 'unchecked', 'checked', 'indeterminate'
    user_qrcode_printed: "indeterminate", // 'unchecked', 'checked', 'indeterminate'
  };

  const [filterText, setFilterText] = useState("");
  const [filterQRCode, setFilterQRCode] = useState("");
  const [filterStatus, setFilterStatus] = useState(initialState);

  const [isLoading, setIsLoading] = useState(null);
  const [isUpdating, setIsUpdating] = useState([]);

  const [selectedRows, setSelectedRows] = React.useState([]);
  const [toggleCleared, setToggleCleared] = React.useState(false);

  const [open, setOpen] = useState(false);
  const [openQRCode, setOpenQRCode] = useState(false);
  const [openQRCodeScanner, setOpenQRCodeScanner] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  /*
  const [dataChanged, setDataChanged] = useState({});

  useSSE(setDataChanged, "/table/users");

  useEffect(() => {
    console.log(dataChanged);
  }, [dataChanged]);
*/
  const removeUpdateOperation = (userId, fieldName) => {
    setIsUpdating((prev) => prev.filter((op) => op.userId !== userId || op.fieldName !== fieldName));
  };

  const isFieldUpdating = (userId, fieldName) => {
    return isUpdating.some((op) => op.userId === userId && op.fieldName === fieldName);
  };

  const handleCellClicked = (row, fieldName) => {
    setIsUpdating((prev) => [...prev, { userId: row.user_id, fieldName }]);

    switch (fieldName) {
      case "user_enabled":
        updateUser(host.id, row.user_id, { [fieldName]: !row[fieldName] })
          .then((response) => {
            setData((currentData) =>
              currentData.map((item) => (item.user_id === response.user_id ? { ...item, ...response } : item))
            );
          })
          .catch((error) => console.log(error))
          .finally(() => removeUpdateOperation(row.user_id, fieldName));
        break;
      case "user_qrcode":
        updateUser(host.id, row.user_id, { user_qrcode: "" })
          .then((response) => {
            setData((currentData) =>
              currentData.map((item) => (item.user_id === response.user_id ? { ...item, ...response } : item))
            );
          })

          .catch((error) => {
            console.log(error);
            // Handle any errors that occurred during the printing or updating process
          })
          .finally(() => {
            removeUpdateOperation(row.user_id, fieldName);
            // Perform any cleanup operations here, like removing the item from a list of updating items
          });
        break;
      case "user_qrcode_printed":
        if (row.user_qrcode_printed || !row["user_qrcode"]) {
          removeUpdateOperation(row.user_id, fieldName);

          setIsUpdating((prev) => [...prev, { userId: row.user_id, fieldName: "user_qrcode" }]);

          setOpenQRCode({ row, fieldName: "user_qrcode" });
        } else {
          doPrint(row["user_qrcode"], row["user_first_name"], row["user_last_name"], undefined, {
            ...host?.event_config,
          })
            .then((response) => {
              if (!response?.result) {
                throw new Error("Printing failed");
              }

              return updateUser(host.id, row.user_id, { user_qrcode_printed: new Date() });
            })
            .then((response) => {
              setData((currentData) =>
                currentData.map((item) => (item.user_id === response.user_id ? { ...item, ...response } : item))
              );
            })
            .catch((error) => {
              setMessage(error);
              setTimeout(() => setMessage(""), 5 * 1000);
            })
            .finally(() => {
              removeUpdateOperation(row.user_id, fieldName);
            });
        }

        /*    EZ NEM TUDOM MIRE VAN //TODO       
        confirm kell
        updateUser(host.id, row.user_id, { user_qrcode: "" })
          .then((response) => {
            if (response[fieldName] === !row[fieldName]) {
                    setData((currentData) =>
              currentData.map((item) => (item.user_id === response.user_id ? { ...item, ...response } : item))
            )
            }
          })
          .catch((error) => console.log(error))
          .finally(() => removeUpdateOperation(row.user_id, fieldName));
 */
        break;

      default:
        break;
    }
  };

  const columns = [
    {
      cell: () => (
        <IconButton color="secondary">
          <Delete />
        </IconButton>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      omit: true,
    },

    {
      name: "ID",
      sortable: true,
      field: "user_id",
      selector: (row) => row["user_id"],
      omit: true,
    },
    {
      name: "Név",
      sortable: true,
      field: "user_name",
      selector: (row) => row["user_name"],
      sortFunction: (rowA, rowB, sortDirection) => customSort(rowA, rowB, "user_name", sortDirection),
      grow: 2,
    },
    {
      name: "Engedélyezett",
      sortable: true,
      field: "user_enabled",
      format: (row) => (
        <IconButton
          className={isFieldUpdating(row["user_id"], "user_enabled") ? "flip-horizontal" : ""}
          onClick={() => handleCellClicked(row, "user_enabled")}
        >
          {row["user_enabled"] ? <CheckCircleOutlineIcon color={"success"} /> : <DoNotDisturbOnIcon color={"error"} />}
        </IconButton>
      ),
      selector: (row) => row["user_enabled"],
      ignoreRowClick: true,
      style: {},
      center: "true",
      compact: "true",
    },
    {
      name: "QR-kód",
      sortable: false,
      field: "user_qrcode",
      selector: (row) => (
        <>
          <div>
            {row["user_qrcode"] && (
              <QRCode
                size={32}
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                value={row["user_qrcode"] || ""}
                viewBox={`0 0 32 32`}
              />
            )}
          </div>
        </>
      ),
      omit: !host?.roles?.includes("manager"),
      center: "true",
    },
    {
      name: "QR-kód nyomtatva",
      sortable: true,
      grow: 2,
      field: "user_qrcode_printed",
      selector: (row) => (
        <>
          <IconButton
            disabled={!row["user_enabled"]}
            className={
              isFieldUpdating(row["user_id"], "user_qrcode_printed") || isFieldUpdating(row["user_id"], "user_qrcode")
                ? "flip-horizontal"
                : ""
            }
            onClick={() => handleCellClicked(row, "user_qrcode_printed")}
            sx={{ paddingRight: 0, paddingLeft: 0 }}
          >
            <QrCode />
          </IconButton>
          {row["user_qrcode_printed"] ? moment(row["user_qrcode_printed"]).locale("hu").format("lll") : ""}
        </>
      ),
    },
    {
      name: "Titulus",
      sortable: true,
      field: "user_title",
      selector: (row) => row["user_title"],
      sortFunction: (rowA, rowB, sortDirection) => customSort(rowA, rowB, "user_title", sortDirection),
      compact: "true",
    },
    {
      name: "Vezetéknév",
      sortable: true,
      field: "user_last_name",
      selector: (row) => row["user_last_name"],
      sortFunction: (rowA, rowB, sortDirection) => customSort(rowA, rowB, "user_last_name", sortDirection),
    },
    {
      name: "Keresztnév",
      sortable: true,
      field: "user_first_name",
      selector: (row) => row["user_first_name"],
      sortFunction: (rowA, rowB, sortDirection) => customSort(rowA, rowB, "user_first_name", sortDirection),
    },
    {
      name: "Regisztráció",
      sortable: true,
      field: "createdAt",
      selector: (row) => (row["createdAt"] ? moment(row["createdAt"]).locale("hu").format("lll") : ""),
    },
    {
      cell: (row) => (
        <IconButton
          className={isFieldUpdating(row["user_id"], "user_name") ? "flip-horizontal" : ""}
          onClick={() =>
            setOpenEdit({
              user_id: row["user_id"],
              user_title: row["user_title"],
              user_first_name: row["user_first_name"],
              user_last_name: row["user_last_name"],
            })
          }
        >
          <Edit />
        </IconButton>
      ),
      ignoreRowClick: true,
    },
    {
      name: "Megvásárolt",
      field: "quantity_purchased",
      omit: true,
    },
    {
      name: "Felhasznált",
      field: "quantity_used",
      omit: true,
    },
    {
      name: "Maradék",
      field: "quantity_remaining",
      omit: true,
    },
  ];

  function fetchData() {
    setIsLoading(true);

    searchUser(
      { roletype: roletype || "attendee" },
      { resourcedata: false, transactiondata: false, handlertransactiondata: false }
    )
      .then((response) => {
        setData(response);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setIsLoading(false));
  }

  useEffect(() => {
    if (!isLoading && data.length === 0) {
      fetchData();
    }
  }, [data]);

  useEffect(() => {
    setToggleCleared(!toggleCleared);
  }, [filterText]);

  const filteredItems = useMemo(() => {
    return data.filter((item) => {
      const textMatch = searchableColumns.some((field) => {
        let itemValue;
        const column = columns.find((column) => column.field === field);
        if (column && column.format) {
          itemValue = column.format(item).toString().toLowerCase();
        } else {
          itemValue = item[field] ? item[field].toString().toLowerCase() : "";
        }

        return itemValue.includes(filterText.toLowerCase());
      });

      const qrcodeMatch = ["user_qrcode"].some((field) => {
        let itemValue;
        const column = columns.find((column) => column.field === field);
        if (column && column.format) {
          itemValue = column.format(item).toString().toLowerCase();
        } else {
          itemValue = item[field] ? item[field].toString() : "";
        }

        return itemValue.includes(filterQRCode);
      });

      const statusMatch = Object.keys(filterStatus).every((field) => {
        const status = filterStatus[field];
        if (status === "indeterminate") {
          return true; // Do not filter out if status is indeterminate
        }

        const fieldValue = item[field];
        if (status === "checked" && fieldValue) {
          return true;
        }
        if (status === "unchecked" && !fieldValue) {
          return true;
        }

        return false;
      });

      return textMatch && qrcodeMatch && statusMatch;
    });
  }, [data, filterText, filterQRCode, filterStatus, searchableColumns]);

  const flattenResources = (source) => {
    let flattened = [];

    source.forEach((user) => {
      // Basic user information without roles and resources
      let userInfo = {
        // user_id: user.user_id,
        _user_name_: user.user_name,
        _user_title_: user.user_title,
        _user_last_name_: user.user_last_name,
        _user_first_name_: user.user_first_name,
        // user_qrcode: user.user_qrcode,
        // user_qrcode_printed: user.user_qrcode_printed,
        // user_email: user.user_email,
        // user_enabled: user.user_enabled,
        // user_company: user.user_company,
        // user_login: user.user_login,
        // createdAt: user.createdAt,
        // updatedAt: user.updatedAt,
      };
      /*
      // If the user has roles, add them to userInfo
      if (user.Roles && user.Roles.length) {
        user.Roles.forEach((role, index) => {
          userInfo[`role_${index + 1}_id`] = role.role_id;
          userInfo[`role_${index + 1}_name`] = role.role_name;
          userInfo[`role_${index + 1}_type`] = role.role_type;
          userInfo[`role_${index + 1}_createdAt`] = role.createdAt;
          userInfo[`role_${index + 1}_updatedAt`] = role.updatedAt;
        });
      }
*/

      if (user.Resources && user.Resources.length) {
        user.Resources.forEach((resource) => {
          if (resource.UserResource) {
            userInfo[`${resource.resource_name} _quantity_purchased_`] =
              resource.UserResource.user_resources_quantity_purchased;
            userInfo[`${resource.resource_name} _quantity_used_`] = resource.UserResource.user_resources_quantity_used;
            userInfo[`${resource.resource_name} _quantity_remaining_`] = resource.UserResource.quantity_remaining;
          }
        });
      }

      flattened.push(userInfo);
    });

    return flattened.map((item) => {
      const newItem = {};
      // For each key in the item, find the corresponding column and use its 'name' as the new key
      Object.keys(item).forEach((key) => {
        const column = columns.find((c) => key.includes("_" + c.field + "_"));
        const newKey = column ? key.replace("_" + column.field + "_", column.name) : key;
        newItem[newKey] = item[key];
      });
      return newItem;
    });
  };

  const contextActions = React.useMemo(() => {
    return (
      <>
        <Button
          variant="contained"
          startIcon={<FileDownload className={isFieldUpdating(0, "export") ? "flip-horizontal" : ""} />}
          disabled={isFieldUpdating(0, "export")}
          sx={{
            minWidth: 0, // Minimize button width
            padding: "6px 0 6px 10px", // Adjust padding as needed
            margin: "10px",
          }}
          onClick={() => {
            setIsUpdating((prev) => [...prev, { userId: 0, fieldName: "export" }]);
            exportToXlsx(flattenResources(selectedRows));
            removeUpdateOperation(0, "export");

            setToggleCleared(!toggleCleared);
          }}
        >
          <span className="hide-on-mobile">Export</span>
        </Button>
        <FormGroup sx={{ display: "none" }}>
          <FormControlLabel control={<Switch defaultChecked color="primary" />} label="Gyors" />
        </FormGroup>
        <Button
          variant="contained"
          startIcon={<QrCode className={isFieldUpdating(0, "print") ? "flip-horizontal" : ""} />}
          disabled={isFieldUpdating(0, "print")}
          sx={{
            minWidth: 0, // Minimize button width
            padding: "6px 0 6px 10px", // Adjust padding as needed
            margin: "10px",
            "& .MuiButton-label": {
              fontSize: { xs: 0, sm: "1rem" },
            },
          }}
          onClick={() => setOpen(true)}
        >
          <span className="hide-on-mobile">QR-kód nyomtatás</span>
        </Button>
      </>
    );
  }, [data, selectedRows]);

  const handleConfirmChange = () => {
    setOpen(false);

    const printList = selectedRows.map((row) => ({
      objTopText: row.user_last_name,
      objBottomText: row.user_first_name,
      objQRCode: row.user_qrcode,
    }));

    doPrint("", "", "", printList, { ...host?.event_config })
      .then((response) => {
        if (!response?.result) {
          throw new Error("Printing failed");
        }

        selectedRows.forEach((row) => {
          updateUser(host.id, row.user_id, { user_qrcode_printed: new Date() })
            .then((response) => {
              setData((currentData) =>
                currentData.map((item) => (item.user_id === response.user_id ? { ...item, ...response } : item))
              );
            })
            .catch((error) => {
              console.log(error);
              // Handle any errors that occurred during the printing or updating process
            });
        });
        setToggleCleared(!toggleCleared);
      })
      .catch((error) => {
        setMessage(error);
        setTimeout(() => setMessage(""), 5 * 1000);
      });
  };

  const handleFilterChange = (event) => {
    setToggleCleared(!toggleCleared);
    const { name } = event.target;

    setFilterStatus((prev) => {
      const nextState =
        prev[name] === "unchecked" ? "checked" : prev[name] === "checked" ? "indeterminate" : "unchecked";

      return { ...prev, [name]: nextState };
    });
  };

  const handleRowSelected = React.useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const paginationComponentOptions = {
    rowsPerPageText: "",
    rangeSeparatorText: "/",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Összes",
  };

  const LinearIndeterminate = () => {
    return (
      <Box
        sx={{
          width: "100%",
          "& > * + *": {
            marginTop: 2,
          },
        }}
      >
        <LinearProgress />
      </Box>
    );
  };

  const ExpandedComponent = ({ data }) => (
    <Box
      display="flex"
      flexWrap={"wrap"}
      alignItems={"stretch"}
      justifyContent={"space-evenly"}
      gap={1}
      textAlign={"center"}
      margin={0}
      padding={0}
      flexDirection={"row"}
    >
      {!data?.Resources && (
        <ProfileData
          id={data?.user_id}
          showOnly={true}
          isEditAllowed={false}
          allResources={false}
          noHeader={true}
          noFooter={true}
          noCloseButton={true}
        />
      )}

      {data?.Resources &&
        data.Resources.map((resource) => (
          <Card
            key={resource.resource_id}
            variant="outlined"
            sx={{ minWidth: 200, boxShadow: 3, marginTop: 1, marginBottom: 1 }}
          >
            <CardHeader
              title={resource.resource_name}
              sx={{ padding: 1 }}
              titleTypographyProps={{ variant: "h6", align: "center" }}
            />
            <CardContent
              sx={{
                position: "relative",
                padding: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "auto",
              }}
            >
              <EditableQuantity key={resource.id} initialQuantity={resource.UserResource.quantity_remaining} />
            </CardContent>
          </Card>
        ))}
    </Box>
  );

  return (
    <>
      <Grid
        container
        gap={1}
        justifyContent={"space-around"}
        alignContent={"space-around"}
        alignItems={"center"}
        marginTop={1}
      >
        <Button
          variant="contained"
          startIcon={<RefreshIcon className={isLoading ? "rotate" : ""} />} // Replace "favorite" with your icon name
          sx={{
            padding: "6px", // Adjust padding as needed
            "& .MuiButton-startIcon": {
              margin: 0, // Remove margin around the icon for a compact look
            },
          }}
          onClick={() => {
            setToggleCleared(!toggleCleared);
            fetchData();
          }}
        >
          Frissítés
        </Button>
        <Grid container flexDirection={"column"} width={"fit-content"} sx={{ padding: 0 }}>
          <FormControlLabel
            control={
              <Checkbox
                color={filterStatus["user_enabled"] !== "indeterminate" ? "secondary" : "primary"}
                name="user_enabled"
                checked={filterStatus["user_enabled"] === "checked"}
                indeterminate={filterStatus["user_enabled"] === "indeterminate"}
                onChange={handleFilterChange}
              />
            }
            label="Engedélyezett"
          />
          <FormControlLabel
            control={
              <Checkbox
                color={filterStatus["user_qrcode_printed"] !== "indeterminate" ? "secondary" : "primary"}
                name="user_qrcode_printed"
                checked={filterStatus["user_qrcode_printed"] === "checked"}
                indeterminate={filterStatus["user_qrcode_printed"] === "indeterminate"}
                onChange={handleFilterChange}
              />
            }
            label="Nyomtatva"
          />
        </Grid>

        <TextField
          color={filterText ? "secondary" : "primary"}
          id="search"
          type="text"
          placeholder="Keresés..."
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
          InputProps={{
            endAdornment: filterText && (
              <InputAdornment position="end">
                <IconButton onClick={() => setFilterText("")} edge="end" size="small" sx={{ color: "red" }}>
                  <CloseIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{
            flexGrow: 1,
            maxWidth: 250,
            "& .MuiInputAdornment-root .MuiIconButton-root": {
              color: "red", // This styles the IconButton color
            },
          }}
        />
        <Button
          color={filterQRCode ? "secondary" : "primary"}
          variant="contained"
          startIcon={<QrCodeScanner />}
          sx={{
            minWidth: 0, // Minimize button width
            padding: "6px", // Adjust padding as needed
            "& .MuiButton-startIcon": {
              margin: 0, // Remove margin around the icon for a compact look
            },
          }}
          onClick={() => (filterQRCode ? setFilterQRCode("") : setOpenQRCodeScanner(true))}
        >
          Keresés
        </Button>
      </Grid>

      <DataTable
        expandableRows
        expandableRowsComponent={ExpandedComponent}
        //        expandableRowDisabled={(row) => row.Resources.length === 0}
        columns={columns}
        data={filteredItems}
        progressPending={isLoading}
        progressComponent={<LinearIndeterminate />}
        sortIcon={sortIcon}
        noDataComponent={t("nodata")}
        contextMessage={{
          singular: "elem",
          plural: "elem",
          message: "kiválasztva",
        }}
        pagination
        paginationRowsPerPageOptions={[10, 50, 100, 500]}
        paginationComponentOptions={paginationComponentOptions}
        selectableRows
        selectableRowsComponent={Checkbox}
        actions
        contextActions={contextActions}
        onSelectedRowsChange={handleRowSelected}
        clearSelectedRows={toggleCleared}
        selectableRowsVisibleOnly
      />

      <ConfirmationDialog
        open={open}
        handleCancelChange={() => setOpen(false)}
        handleConfirmChange={handleConfirmChange}
        titleText={t("Biztosan elindítja nyomtatást?")}
      />

      <ConfirmationDialog
        open={openQRCode}
        handleCancelChange={() => {
          removeUpdateOperation(openQRCode["row"].user_id, openQRCode["fieldName"]);

          setOpenQRCode(false);
        }}
        handleConfirmChange={() => {
          setOpenQRCode(false);

          handleCellClicked(openQRCode["row"], openQRCode["fieldName"]);
        }}
        titleText={t("Új QR-kód generálás indulhat?")}
        descriptionText={t("FIGYELEM! A régi QR-kód érvénytelenné válik. Ne felejtse el kinyomtatni az új QR-kódot!")}
      />

      <Dialog open={openQRCodeScanner} onClose={() => setOpenQRCodeScanner(false)}>
        <QRScanner
          setResult={(result) => {
            setOpenQRCodeScanner(false);
            setFilterQRCode(result);
          }}
        />
      </Dialog>

      <Dialog open={openEdit} onClose={() => setOpenEdit(false)}>
        <UserForm
          onSubmit={(formData) => {
            setOpenEdit(false);

            const fieldName = "user_name";
            setIsUpdating((prev) => [...prev, { userId: formData.user_id, fieldName }]);

            const { user_id, ...fields } = formData;

            fields["user_name"] = `${formData.user_title || ""} ${formData.user_last_name} ${
              formData.user_first_name
            }`.trim();

            updateUser(host.id, user_id, fields)
              .then((response) => {
                setData((currentData) =>
                  currentData.map((item) => (item.user_id === response.user_id ? { ...item, ...response } : item))
                );
              })
              .catch((error) => console.log(error))
              .finally(() => removeUpdateOperation(formData.user_id, fieldName));
          }}
          initialFormData={openEdit}
        />
      </Dialog>
    </>
  );
};

export default SearchUserForm;
