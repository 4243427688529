import React from "react";
import { useTranslation } from "react-i18next";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function Loading() {
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
 
    >
      <Box textAlign="center">
        <CircularProgress size={24} />
        <Typography variant="subtitle1" mt={2}>
          {t("loading")}
        </Typography>
      </Box>
    </Box>
  );
}
