import React, { useState, useEffect } from "react";
import { setUserResourceQuantity, searchUser } from "../../services/apiService";
import { Box, Typography, Button, Grid } from "@mui/material";
import { Card, CardContent, CardHeader, CardActions } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import ProfileData from "../profile/ProfileData";
import QRScanner from "../qrscanner/QRScanner";
import MultilineText from "../../helpers/MultilineText";
import Allowed from "./ResourceUseAllowed";
import Denied from "./ResourceUseDenied";
import Loading from "../../components/Loading";
import { useHost } from "../../context/HostContext";
import { useMessage } from "../../context/MessageContext";
import { useTranslation } from "react-i18next";
import LinearProgressWithCountdown from "../../components/LinearProgressWithCountdown";
import { isZeroHost } from "../../functions";

const ResourceUse = ({ selectedResource }) => {
  const { t } = useTranslation();

  const { host } = useHost();

  const { setMessage } = useMessage();

  const [loading, setLoading] = useState(false);

  const [allowed, setAllowed] = useState(null);

  const [profile, setProfile] = useState(null);

  const [showProfile, setShowProfile] = useState(false);

  const [isProcessing, setIsProcessing] = useState(false);

  const [denyText, setDenyText] = useState(null);

  useEffect(() => {
    if (allowed !== null)
      if (allowed || (!allowed && !denyText) || host?.roles?.includes("zerohost"))
        setTimeout(
          () => {
            setAllowed(null);
            setMessage("");
          },
          allowed
            ? host?.eventconfig?.resource_use_timeout_allowed || 750
            : host?.eventconfig?.resource_use_timeout_denied || 1000
        );
  }, [allowed, denyText]);

  useEffect(() => {
    if (host?.roles?.includes("zerohost")) {
      localStorage.setItem("zeroHost", "resource-usage");
      localStorage.setItem("zeroHostFacingMode", "user");
      setMessage({ text: "zeroHost védelem bekapcsolva!", type: "warning", duration: 5 * 1000 });
    }
  }, [host]);

  const handleUse = (resource) => {
    setIsProcessing(true);

    setMessage("");

    if (!host?.id) {
      setMessage({ text: t("error.host-id-missing"), type: "error" });

      setDenyText(t("error.host-id-missing"));
      setAllowed(false);

      setIsProcessing(false);
      return;
    }

    if (!resource.resource_id) {
      setMessage({ text: t("error.resource-id-missing"), type: "error" });

      setDenyText(t("error.resource-id-missing"));
      setAllowed(false);

      setIsProcessing(false);
      return;
    }

    setUserResourceQuantity(host.id, profile.user_id, resource.resource_id, 1, "", -1)
      .then(() => {
        //response
        //response.data  : user_resource
        setAllowed(true);
        setDenyText("");

        setProfile(null);

        setShowProfile(false);
      })
      .catch((error) => {
        if (error?.status === 403) {
          //TODO
        }
        setMessage(error);

        setDenyText(t(error?.response?.data || error?.message || "error.unknown"));

        setAllowed(false);

        setProfile(null);

        setShowProfile(false);
      })
      .finally(() => setIsProcessing(false));
  };

  const handleNoUse = (e) => {
    e?.preventDefault();

    if (!isProcessing) {
      setMessage("");

      setDenyText("");
      setAllowed(false);

      setProfile(null);

      setShowProfile(false);
    }
  };

  function getPageHeaderText() {
    if (profile) {
      if (!isZeroHost()) {
        return t("usage-location", { selectedResource: selectedResource?.name });
      } else {
        return "";
      }
    }

    if (!profile && isZeroHost()) {
      return t("usage-scan-zerohost", { selectedResource: selectedResource?.name });
    }

    return t("usage-scan", { selectedResource: selectedResource?.name });
  }

  function remainingQuantity(resource) {
    return resource?.UserResource?.quantity_remaining || 0;
  }

  function showCards() {
    const resources = profile.Resources.filter(
      (resource) =>
        resource.resource_id === selectedResource.id ||
        (isZeroHost() && resource.resource_id === selectedResource.id + 1)
    );

    if (!resources || resources.length === 0) {
      return null;
    }

    return resources.map((resource) => (
      <Card
        key={resource.resource_id}
        sx={{
          mb: 2,
          boxShadow: 3,
          border: "1px solid rgba(0, 0, 0, 0.12)",
          backgroundColor: "background.paper", // Use a theme color for slight differentiation
        }}
      >
        <CardHeader
          title={isZeroHost() ? resource.resource_name : profile.user_name}
          titleTypographyProps={{ align: "center" }} // Center align the title
          subheader={isZeroHost() ? profile.user_name : ""}
          subheaderTypographyProps={{ align: "center", color: "white" }} // Center align the title
          sx={{
            backgroundColor: remainingQuantity(resource) === 0 ? "red" : "green",
            color: "white",
          }}
        />
        <CardContent
          sx={{
            padding: 0,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "auto",
          }}
        >
          <Typography
            textAlign={"center"}
            sx={{
              mt: 0,
              mb: 2, // Adjust top margin to control space between this text and the card header
              fontWeight: "bold", // Optional: make it bold to stand out
            }}
          >
            {t("")}
          </Typography>

          <Typography
            variant="h4"
            align="center"
            sx={{
              width: "90px",
              height: "90px",
              lineHeight: "90px", // Aligns text vertically
              borderRadius: "50%", // Makes circle shape
              color: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: remainingQuantity(resource) === 0 ? "red" : "green",
            }}
          >
            {remainingQuantity(resource)}
          </Typography>

          <Typography
            textAlign={"center"}
            sx={{
              mt: 1,
              mb: 0, // Adjust top margin to control space between this text and the card header
              fontWeight: "bold", // Optional: make it bold to stand out
            }}
          >
            {t("uses-allowed")}
          </Typography>
        </CardContent>
        <CardActions sx={{ justifyContent: "space-evenly", alignItems: "stretch" }}>
          {remainingQuantity(resource) > 0 && (
            <Button
              disabled={remainingQuantity(resource) === 0 || isProcessing}
              variant="contained"
              sx={{
                backgroundColor: "green",
                "&:hover": { backgroundColor: "darkgreen" },
              }}
              onClick={(e) => {
                e?.preventDefault();
                handleUse(resource);
              }}
            >
              {isProcessing ? (
                <CircularProgress
                  size={"3rem"}
                  sx={{
                    color: "white",
                  }}
                />
              ) : (
                t("usage-use")
              )}
            </Button>
          )}
        </CardActions>
      </Card>
    ));
  }

  if (!selectedResource?.id) return null;

  return (
    <>
      <Allowed open={allowed === true} doClose={() => setAllowed(null)} />
      <Denied open={allowed === false} doClose={() => setAllowed(null)} buttonText={denyText} />

      <Grid item xs={12} justifyContent="center" alignItems="center">
        <MultilineText text={getPageHeaderText()} />
      </Grid>

      {loading && <Loading />}

      {!allowed && !loading && !isProcessing && profile === null && (
        <Grid item justifyContent="center" alignItems="center">
          <QRScanner
            setResult={async (qrcode) => {
              try {
                setLoading(true);

                setMessage("");
                setDenyText("");
                setAllowed(null);

                const response = await searchUser(
                  { qrcode },
                  { valid: true, resourcedata: true, handlertransactiondata: false, transactiondata: false }
                );

                if (response && response.length === 1) {
                  const profileData = response[0];

                  if (host?.roles?.includes("zerohost") && host?.id === profileData.user_id) {
                    localStorage.removeItem("zeroHost");
                    localStorage.removeItem("zeroHostLocation");
                    setMessage({ text: "zeroHost védelem kikapcsolva!", type: "warning", duration: 10 * 1000 });
                  }

                  setProfile(profileData);

                  setLoading(false);
                } else {
                  setMessage({ text: t("invalid-qr-code"), type: "error", duration: 5 * 1000 });

                  setDenyText(t("invalid-qr-code"));
                  setAllowed(false);

                  setLoading(false);
                }
              } catch (error) {
                setMessage(error);

                setDenyText(t("invalid-qr-code"));

                setAllowed(false);

                setLoading(false);
              }
            }}
            facingMode={localStorage.getItem("zeroHostFacingMode")}
          />
        </Grid>
      )}

      {profile && (
        <Box>
          {showCards()}

          {isZeroHost() && (
            <Grid item xs={12} justifyContent="center" alignItems="center" textAlign={"center"} marginTop={2}>
              <Typography
                textAlign={"center"}
                sx={{
                  mt: 0,
                  mb: 0,
                }}
              >
                {t("zerohost-press-for-use")}
              </Typography>
              <LinearProgressWithCountdown
                duration={host?.eventconfig?.zerohost_auto_close_timeout || 10 * 1000}
                text={t("time-to-close")}
                callback={handleNoUse}
              />
            </Grid>
          )}

          <Button
            fullWidth
            variant="contained"
            sx={{
              mt: 2,
              mb: 2,
              backgroundColor: "grey",
              "&:hover": { backgroundColor: "darkgrey" },
            }}
            onClick={handleNoUse}
          >
            {t("usage-nouse")}
          </Button>

          {showProfile ? (
            <>
              <ProfileData user={profile} showOnly onButtonClick={() => setShowProfile(false)} />
            </>
          ) : (
            !isZeroHost() && (
              <Button
                fullWidth
                variant="contained"
                sx={{
                  mt: 2,
                  mb: 2,
                }}
                onClick={() => setShowProfile(true)}
              >
                {t("further-usage-options")}
              </Button>
            )
          )}
        </Box>
      )}
    </>
  );
};

export default ResourceUse;
