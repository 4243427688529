import React, { useState } from "react";
import { Typography, Input, Box, IconButton, Dialog, DialogActions, DialogTitle, Button } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useTranslation } from "react-i18next";

const EditableQuantity = ({ resourceId, initialQuantity, onQuantityChange }) => {
  const { t } = useTranslation();

  const [isEditing, setIsEditing] = useState(false);

  const [open, setOpen] = useState(false);

  const [quantity, setQuantity] = useState(0);

  const handleDoubleClick = () => {
    if (onQuantityChange) {
      setIsEditing(true);
      setQuantity(0);
    }
  };

  const handleChange = (e) => {
    setQuantity(e.target.value);
  };

  const handleConfirmChange = () => {
    // Close the dialog

    setIsEditing(false);
    setQuantity(initialQuantity + parseInt(quantity));
    onQuantityChange({ resourceId, initialQuantity, quantityChange: parseInt(quantity) });

    setOpen(false);
  };

  const handleCancelChange = () => {
    // Just close the dialog without applying changes
    setOpen(false);
  };
  return isEditing ? (
    <>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ display: "flex", flexDirection: "column", ml: 1, gap: 2 }}>
          <IconButton
            size="small"
            onClick={() => {
              setQuantity(parseInt(quantity) + 1);
            }}
            color="primary"
          >
            <AddIcon />
          </IconButton>
          <IconButton
            size="small"
            disabled={parseInt(quantity) <= 0 - initialQuantity}
            onClick={() => {
              if (parseInt(quantity) > 0 - initialQuantity) setQuantity(parseInt(quantity) - 1);
            }}
            color="secondary"
          >
            <RemoveIcon />
          </IconButton>
        </Box>
        <Input
          type="number"
          value={quantity}
          onChange={handleChange}
          inputProps={{ min: 0 - initialQuantity }}
          sx={{
            width: "60px",
            textAlign: "center",
            "& input": {
              textAlign: "center",
            },
            color: quantity < 0 ? "red" : "inherit",
          }}
        />
        <Box sx={{ display: "flex", flexDirection: "column", ml: 1, gap: 2 }}>
          <IconButton
            size="small"
            onClick={() => {
              if (quantity == 0) {
                setIsEditing(false);
                setQuantity(initialQuantity + parseInt(quantity));
              } else setOpen(true);
            }}
            color="primary"
          >
            <CheckIcon />
          </IconButton>
          <IconButton
            size="small"
            onClick={() => {
              setIsEditing(false);
              setQuantity(initialQuantity);
            }}
            color="secondary"
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>

      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          width: 32,
          height: 32,
          borderRadius: "50%",
          backgroundColor: "secondary.main",
          color: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "0.75rem",
          userSelect: "none",
        }}
      >
        {initialQuantity}
      </Box>

      <Dialog
        open={open}
        onClose={handleCancelChange}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            backgroundColor: quantity < 0 ? "red" : "inherit",
          }}
        >
          {t("confirm-quantity-change")}
        </DialogTitle>
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              marginRight: "20px",
              backgroundColor: "grey",
              "&:hover": { backgroundColor: "darkgrey" },
            }}
            onClick={handleCancelChange}
          >
            {t("no")}
          </Button>
          <Button
            variant="contained"
            sx={{
              marginLeft: "20px",
              backgroundColor: "green",
              "&:hover": { backgroundColor: "darkgreen" },
            }}
            onClick={handleConfirmChange}
            autoFocus
          >
            {t("yes")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  ) : (
    <Typography
      variant="h5"
      sx={{
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "60px",
        height: "60px",
        lineHeight: "60px",
        borderRadius: "50%",
        color: onQuantityChange ? "yellow" : "white",
        backgroundColor: initialQuantity === 0 ? "red" : "green",
        userSelect: "none",
      }}
      onDoubleClick={handleDoubleClick}
    >
      {initialQuantity}
    </Typography>
  );
};
export default EditableQuantity;
