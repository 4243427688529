import React from "react";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import { Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText } from "@mui/material";

function ConfirmationDialog({ titleText, descriptionText, open, handleCancelChange, handleConfirmChange }) {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open} // Controls if the dialog is open or closed
      onClose={handleCancelChange} // Function called when the dialog is requested to be closed
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
    >
      {titleText && (
        <DialogTitle id="confirm-dialog-title" sx={{}}>
          {titleText}
        </DialogTitle>
      )}

      {descriptionText && (
        <DialogContent>
          <DialogContentText id="content-dialog-description">{descriptionText} </DialogContentText>
        </DialogContent>
      )}

      <DialogActions>
        <Button
          variant="contained"
          sx={{
            marginRight: "20px",
            backgroundColor: "grey",
            "&:hover": { backgroundColor: "darkgrey" },
          }}
          onClick={handleCancelChange}
        >
          {t("no")}
        </Button>
        <Button
          variant="contained"
          sx={{
            marginLeft: "20px",
            backgroundColor: "green",
            "&:hover": { backgroundColor: "darkgreen" },
          }}
          onClick={handleConfirmChange} // Calls the confirm function
          autoFocus
        >
          {t("yes")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmationDialog;
