import { createTheme } from "@mui/material/styles";
//import { indigo, purple } from "@mui/material/colors";

const theme = (mode) =>
  createTheme({
    palette: { mode },
    components: {
      MuiCssBaseline: {},
      MuiCard: {
        styleOverrides: {
          root: {
            marginBottom: "25px",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
            transition: "transform 0.3s ease-in-out",
            "&:hover": {
              boxShadow: "0px 6px 24px rgba(0, 0, 0, 0.2)",
            },
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            "&:not(.Mui-disabled)": {
              "@media (max-width: 600px)": {
                marginTop: 5,
                marginBottom: 5,
                paddingTop: 10,
                paddingBottom: 10,
              },
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            boxShadow: "0 3px 4px 2px rgba(0, 0, 0, .7)",
          },

          variants: [
            {
              props: { variant: "contained", color: "primary" },
              style: {},
            },
            {
              props: { variant: "contained", color: "secondary" },
              style: {},
            },
          ],
        },
      },
    },
  });

export default theme;
