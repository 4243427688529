import React from "react";
import Backdrop from "@mui/material/Backdrop";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

function Allowed({ open, doClose }) {
  // Directly use open prop to control visibility
  return (
    open && (
      <div style={{ position: "fixed", top: 0, left: 0, width: "100vw", height: "100vh", zIndex: 2000 }}>
        <Backdrop
          onClick={doClose}
          open={open}
          style={{
            color: "#fff",
            backgroundColor: "rgba(0, 255, 0, 0.7)",
            zIndex: -1,
            position: "absolute",
            width: "100%",
            height: "100%",
          }}
        >
          <CheckCircleOutlineIcon style={{ fontSize: "100px" }} />
        </Backdrop>
      </div>
    )
  );
}

export default Allowed;
