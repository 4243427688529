import React, { useState, useEffect } from "react";
import { QrScanner } from "@yudiel/react-qr-scanner";
import { isURL, getUUID } from "../../functions";

const QRScanner = ({ handleScan, handleError, setResult, facingMode }) => {
  const [data, setData] = useState("");

  const [stopDecoding, setStopDecoding] = useState(false);

  const handleDecode = (result) => {
    setStopDecoding(true);
    setTimeout(() => setStopDecoding(false), 500);

    const vcardUUID = extractUUIDFromVCard(result);
    if (vcardUUID) result = vcardUUID;

    const URLUUID = extractUUIDFromURL(result);
    if (URLUUID) result = URLUUID;

    setData(result);
    setResult?.(result);
  };

  useEffect(() => {
    // This effect is for handling any logic you might want to execute when data changes.
  }, [data]);

  const extractUUIDFromVCard = (vCardString) => {
    if (vCardString.trim().startsWith("BEGIN:VCARD") && vCardString.endsWith("END:VCARD")) {
      const uidLine = vCardString.split("\n").find((line) => line.startsWith("UID:"));
      if (uidLine) {
        const uuid = uidLine.split(":")[1].trim();
        return uuid;
      }
    }
    return null;
  };

  const extractUUIDFromURL = (URLString) => {
    if (isURL(URLString.trim())) {
      return getUUID(URLString);
    }
    return null;
  };

  return (
    <div style={{ width: "300px", height: "300px", margin: "auto" }}>
      <QrScanner
        stopDecoding={stopDecoding}
        onDecode={handleDecode}
        onError={handleError}
        onResult={handleScan}
        constraints={{
          facingMode: facingMode || "environment"
        }}
      />
    </div>
  );
};

export default QRScanner;
