// src/services/apiService.js
import axios from "axios";

// eslint-disable-next-line no-undef
const BASE_URL = process.env.REACT_APP_API_URL?.trim() || "";

const getURL = (uri) => {
  return BASE_URL + uri || "";
};

const checkBackendAvailability = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/check`);
    return { available: true, data: response.data };
  } catch (error) {
    return { available: false, error: error.response };
  }
};

const checkVersion = async () => {
  try {
    const url = `/version.json?nocache=${new Date().getTime()}`;
    const response = await axios.get(url);
    return { version: response };
  } catch (error) {
    return { version: "" };
  }
};

const verifyToken = async () => {
  try {
    const token = localStorage.getItem("authToken");

    if (!token) return false;

    const response = await axios.post(`${BASE_URL}/verifyToken`, {}, { headers: { Authorization: `Bearer ${token}` } });
    const isValid = response.data.auth;

    if (!isValid) localStorage.removeItem("authToken");

    return isValid;
  } catch (error) {
    if (error?.response?.status === 403) {
      localStorage.removeItem("authToken");
      return false;
    } else return true;
  }
};

const login = async (loginid, qrcode, password, fingerprint) => {
  try {
    const response = await axios.post(`${BASE_URL}/login`, {
      loginid: loginid || null,
      qrcode: qrcode || null,
      password,
      fingerprint,
    });

    if (response.data) {
      const { token } = response.data;

      return { success: true, token };
    } else {
      return { success: false, message: "Authentication failed." };
    }
  } catch (error) {
    return { success: false, message: "Authentication failed." };
  }
};

const searchResource = async ({ id, qrcode, name, enabled }, options) => {
  try {
    const token = localStorage.getItem("authToken");

    const response = await axios.post(
      `${BASE_URL}/resources/search`,
      {
        id,
        qrcode,
        name,
        enabled,
        options,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const searchUser = async ({ id, qrcode, name, enabled, roletype }, options) => {
  try {
    const token = localStorage.getItem("authToken");

    const response = await axios.post(
      `${BASE_URL}/users/search`,
      {
        id,
        qrcode,
        name,
        enabled,
        roletype,
        options,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const searchTransaction = async ({ id }, options) => {
  try {
    const token = localStorage.getItem("authToken");

    const response = await axios.post(
      `${BASE_URL}/resourcetransactions/search`,
      {
        id,
        options,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const setUserResourceQuantity = async (hostId, id, resourceId, quantity, description, sign) => {
  try {
    const token = localStorage.getItem("authToken");

    const response = await axios.post(
      `${BASE_URL}/userresources/id/${id}/resource/${resourceId}`,
      {
        handlerid: hostId,
        quantity,
        description,
        sign,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const updateUser = async (handlerid, id, fields, options) => {
  try {
    const token = localStorage.getItem("authToken");

    const response = await axios.post(
      `${BASE_URL}/users/update/${id}`,
      {
        handlerid,
        updatedfields: fields,
        options,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const createUser = async ({ title, first_name, last_name, name, email, company, enabled, roletype }, options) => {
  try {
    const token = localStorage.getItem("authToken");

    const response = await axios.post(
      `${BASE_URL}/users/create`,
      {
        user_title: title,
        user_first_name: first_name,
        user_last_name: last_name,
        user_name: name,
        user_email: email,
        user_company: company,
        user_enabled: enabled,
        roletype,
        options,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getUserMissingResources = async (id) => {
  try {
    const token = localStorage.getItem("authToken");

    const response = await axios.get(`${BASE_URL}/userresources/user/${id}/resources/missing`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getTableFields = async (tableName) => {
  try {
    const token = localStorage.getItem("authToken");

    const response = await axios.get(`${BASE_URL}/database/table/${tableName}/fields`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export {
  getURL,
  checkBackendAvailability,
  checkVersion,
  verifyToken,
  login,
  setUserResourceQuantity,
  searchUser,
  searchResource,
  createUser,
  updateUser,
  getUserMissingResources,
  getTableFields,
  searchTransaction,
};
