import React, { useCallback, useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { Box, Button, Typography, Paper } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DataTableAdvanced from "../../helpers/DataTableAdvanced";
import DataTableImport from "../../helpers/DataTableImport";
import { getTableFields, searchResource } from "../../services/apiService";

function generateColumns(data) {
  if (!data || data.length === 0) return [];

  // Az első elem kulcsainak felhasználásával generáljuk a columns tömböt
  const columns = Object.keys(data[0]).map((key) => ({
    name: key,
    selector: (row) => row[key],
    sortable: true,
    field: key,
  }));

  return columns;
}

const ExcelToJson = () => {
  const [items, setItems] = useState([]);
  const [columns, setColumns] = useState([]);

  const [fields, setFields] = useState([]);

  const [selection, setSelection] = useState({});

  useEffect(() => {
    getTableFields("users").then((response) => {
      const usersFields = response?.map((field) => `user: ${field}`);

      searchResource({ enabled: true }).then((response) => {
        const resourcesFields = response.map(
          (resource) => `resource_${resource.resource_id}: ${resource.resource_name}`
        );

        setFields(usersFields.concat(resourcesFields));
      });
    });
  }, []);

  const handleFileUpload = (file) => {
    setItems([]);
    setSelection({});

    if (file) {
      const reader = new FileReader();

      reader.onload = (evt) => {
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: "binary" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);

        setColumns(generateColumns(data));
        setItems(data);
      };

      reader.readAsBinaryString(file);
    }
  };

  const handleInputChange = (e) => {
    const file = e.target.files[0];
    handleFileUpload(file);
  };

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files && files[0]) {
      handleFileUpload(files[0]);
    }
  }, []);

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
  }, []);

  return (
    <Box sx={{ p: 3, display: "flex", flexDirection: "column", alignItems: "center", gap: 2 }}>
      {!items?.length && (
        <>
          <Typography variant="h6">Upload Excel File</Typography>
          <Paper
            variant="outlined"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: 200,
              width: 300,
              backgroundColor: "#f5f5f5",
              cursor: "pointer",
              borderStyle: "dashed",
              borderColor: "#3f51b5",
            }}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
          >
            <CloudUploadIcon sx={{ fontSize: 50, color: "#3f51b5" }} />
            <Typography>Drag & Drop or Click to Select Files</Typography>
            <Button variant="contained" component="label" sx={{ mt: 2 }}>
              Upload File
              <input type="file" hidden accept=".xlsx, .xls" onChange={handleInputChange} />
            </Button>
          </Paper>
        </>
      )}

      {selection?.selectedCount && <DataTableImport fields={fields} columns={columns} data={selection.selectedRows} />}

      {items && (items?.length > 0)  && !selection?.selectedCount && (
        <DataTableAdvanced columns={columns} data={items} onSelection={setSelection} />
      )}
    </Box>
  );
};

export default ExcelToJson;
