import React, { createContext, useState, useEffect, useContext } from "react";
import { jwtDecode } from "jwt-decode";

const HostContext = createContext({
  host: null,
  updateHost: () => {},
});

export const HostProvider = ({ children }) => {
  const [host, setHost] = useState(null);

  useEffect(() => {
    updateHost();
  }, []);

  const updateHost = () => {
    const token = localStorage.getItem("authToken");

    if (!token) {
      setHost(null);
      return;
    }

    const userData = getUserDataFromToken(token);

    setHost(userData);
  };

  const getUserDataFromToken = () => {
    const token = localStorage.getItem("authToken");

    if (!token) {
      return null;
    }

    try {
      const decoded = jwtDecode(token);

      return decoded;
    } catch (error) {
      console.log("Token decoding failed:", error);
      return null;
    }
  };

  return <HostContext.Provider value={{ host, updateHost }}>{children}</HostContext.Provider>;
};

export const useHost = () => useContext(HostContext);
