import React, { useState, useEffect } from "react";
import * as bpac from "../../vendor/bpac";
import { Button, Box, Typography, Alert } from "@mui/material";
import { Card, CardHeader, CardContent, CardActions } from "@mui/material";
import { TextField } from "@mui/material";

import { useTranslation } from "react-i18next";

const QRCodePrinter = ({ name, company, qrcode, setup }) => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    previewSize: "500",
  });

  const [previewSrc, setPreviewSrc] = useState("");
  const [isPrinting, setIsPrinting] = useState(false);
  const [isBpac, setIsBpac] = useState(false);
  const [isBpacExtension, setIsBpacExtension] = useState(false);

  const [browserType, setBrowserType] = useState("");

  const DATA_FOLDER = `${window.location.origin}${window.location.pathname}`;
  const TEMPLATE_NAME = "/QRCode.lbx";

  useEffect(() => {
    if (bpac) {
      try {
        const objDoc = new bpac.IDocument();
        if (objDoc) {
          if (bpac.IsExtensionInstalled() == false) {
            setIsBpacExtension(false);
            const agent = window.navigator.userAgent.toLowerCase();

            const isedge = agent.indexOf("edg") !== -1;
            if (isedge) setBrowserType("edge");
            const ischrome = agent.indexOf("chrome") !== -1 && agent.indexOf("opr") === -1;
            if (ischrome) setBrowserType("chrome");
          } else setIsBpacExtension(true);

          setIsBpac(true);
        } else setIsBpac(false);
      } catch (error) {
        setIsBpac(false);
        return;
      }
    } else {
      setIsBpac(false);
      return;
    }
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (!/^\d*\.?\d+$/.test(value) && value !== "") {
      console.warn("Input must be a positive number.");
      return;
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const doInstallExtension = () => {
    switch (browserType) {
      case "chrome":
        window.open("https://chrome.google.com/webstore/detail/ilpghlfadkjifilabejhhijpfphfcfhb", "_blank");

        break;
      case "edge":
        window.open(
          "https://microsoftedge.microsoft.com/addons/detail/brother-bpac-extension/kmopihekhjobijiipnloimfdgjddbnhg",
          "_blank"
        );

        break;

      default:
        break;
    }
  };

  const doPrint = async (preview) => {
    setIsPrinting(true);

    try {
      const strPath = DATA_FOLDER + TEMPLATE_NAME;

      const objDoc = bpac.IDocument;
      const ret = await objDoc.Open(strPath);

      if (ret === true) {
        // Set values based on template object names
        const objCompany = await objDoc.GetObject("objTopText");
        if (objCompany) objCompany.Text = company;
        const objQRCodeText = await objDoc.GetObject("objQRCodeText");
        if (objQRCodeText) objQRCodeText.Text = qrcode;
        const objQRCode = await objDoc.GetObject("objQRCode");
        objQRCode.Text = qrcode;
        const objName = await objDoc.GetObject("objBottomText");
        if (objName) objName.Text = name;

        // Print or preview
        if (preview === "") {
          objDoc.StartPrint("", 0);
          objDoc.PrintOut(1, 0);
          objDoc.EndPrint();
        } else {
          const image = await objDoc.GetImageData(4, 0, parseInt(formData.previewSize));
          setPreviewSrc(image);
        }

        await objDoc.Close();
      }
    } catch (e) {
      console.error(e);
    }

    setIsPrinting(false);
  };

  return (
    <Box textAlign={"center"} sx={{ p: 2 }}>
      <Card
        sx={{
          boxShadow: 3,
          border: "1px solid rgba(0, 0, 0, 0.12)",
          backgroundColor: "background.paper",
        }}
      >
        <CardHeader
          title={name}
          titleTypographyProps={{ align: "center" }}
          subheader={t("qrcode-printing")}
          subheaderTypographyProps={{ align: "center", color: "white" }}
          sx={{
            backgroundColor: "secondary.main",
            color: "secondary.contrastText",
          }}
        />

        <Typography
          textAlign={"center"}
          sx={{ backgroundColor: "secondary.light", color: "secondary.contrastText", px: 1, py: 1 }}
        >
          {company}
        </Typography>

        <CardActions sx={{ justifyContent: "center", flexWrap: "wrap", gap: "20px" }}>
          <Button variant="contained" onClick={() => doPrint("")} disabled={isPrinting || !isBpac || !isBpacExtension}>
            {t("button-print")}
          </Button>
          <Button
            variant="outlined"
            onClick={() => doPrint("preview")}
            disabled={isPrinting || !isBpac || !isBpacExtension}
            sx={{ ml: 1 }}
          >
            {t("button-print-preview")}
          </Button>
          {setup && (
            <TextField
              type="number"
              required
              inputProps={{ min: 0 }}
              autoFocus
              label={t("printer-setup-qrcode-preview-size")}
              name="previewSize"
              value={formData.previewSize}
              onChange={handleChange}
            />
          )}
          {isBpac && !isBpacExtension && browserType && (
            <>
              <Button variant="contained" onClick={doInstallExtension}>
                Bővítmény telepítése
              </Button>
              <Alert severity="warning" style={{ marginTop: "10px" }}>
                {t("A bővítmény telepítése után frissíteni kell az oldalt!")}
              </Alert>
            </>
          )}
        </CardActions>

        <CardContent>
          {isBpac && !isBpacExtension && !browserType && (
            <Alert severity="error" style={{ marginTop: "10px" }}>
              {t("Unsupported browser. Please use Chrome or Edge.")}
            </Alert>
          )}

          {previewSrc && isBpac && (
            <Box
              sx={{
                width: "auto",
                height: 500,
                overflow: "hidden",
                position: "relative",
              }}
            >
              <img
                id="previewArea"
                src={previewSrc}
                alt="Preview"
                style={{
                  position: "absolute",
                  left: "0",
                  top: "0",
                }}
              />
            </Box>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default QRCodePrinter;
