import React, { useState } from "react";
import { Button, TextField, Grid, Box} from "@mui/material";
import { useTranslation } from "react-i18next";

const UserForm = ({initialFormData, onSubmit }) => {
  const [formData, setFormData] = useState(initialFormData || {
    user_title: "",
    user_first_name: "",
    user_last_name: "",
  });
  const { t } = useTranslation();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(formData);
  };

  return (
    <Box m={2}>
 
    <form onSubmit={handleSubmit} >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label={t("attendee-title")}
            name="user_title"
            value={formData.user_title}
            onChange={handleChange}
            />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            label={t("attendee-last-name")}
            name="user_last_name"
            value={formData.user_last_name}
            onChange={handleChange}
            />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            label={t("attendee-first-name")}
            name="user_first_name"
            value={formData.user_first_name}
            onChange={handleChange}
            />
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" fullWidth variant="contained">
            {t("submit")}
          </Button>
        </Grid>
      </Grid>
    </form>
            </Box>
  );
};

export default UserForm;
