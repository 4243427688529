// FingerprintContext.js
import React, { createContext, useState, useEffect, useContext } from "react";
import Alert from "@mui/material/Alert";
import { useTranslation } from "react-i18next";

const MessageContext = createContext({
  messageText: "",
  messageType: "",
  setMessage: () => {},
});

export const MessageProvider = ({ children }) => {
  const { t } = useTranslation();

  const [messageText, setText] = useState("");
  const [messageType, setType] = useState(""); // success or error

  const [isNetworkError, setIsNetworkError] = useState(false);

  useEffect(() => {
    setMessage({ text: "", type: "", duration: 0 });
  }, []);

  useEffect(() => {
    setIsNetworkError(messageText === "Network Error");
  }, [messageText]);

  const setMessage = (message) => {
    if (message !== undefined && message !== null) {
      if (message?.error || message?.data) {
        const error = message?.error || message;
        const text = `${message?.text ? message.text : ""} ${error.data} ( ${error.status} ${error.statusText})`;
        setText(t(text.trim()));
        setType("error");
      } else if (message?.response?.data) {
        setText(t(message.response.data));
        setType("error");
      } else if (message?.message) {
        setText(t(message.message));
        setType("error");
      } else {
        if (message === "") {
          setText("");
          setType("");
        } else {
          setText(t(message.text));
          setType(message.type);
        }
      }

      if (message?.duration) {
        setTimeout(() => setText(""), message.duration);
      }
    }
  };

  return (
    <MessageContext.Provider value={{ messageText, messageType, setMessage }}>
      <>
        {isNetworkError && messageText && (
          <Alert severity={messageType} style={{ textAlign: "center", marginTop: "0"}}>
            {messageText}
          </Alert>
        )}
        {children}
      </>
    </MessageContext.Provider>
  );
};

export const useMessage = () => useContext(MessageContext);
