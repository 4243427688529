import React, { useMemo, useState } from "react";
import { Checkbox } from "@mui/material";
import DataTable from "react-data-table-component";
import { TextField, MenuItem, FormControl, Select, Grid } from "@mui/material";
// import DataTablePagination from "./DataTablePagination";

const selectProps = { indeterminate: (isIndeterminate) => isIndeterminate };

const DataTableAdvanced = ({ columns, data, onSelection }) => {
  const [filterText, setFilterText] = useState("");
  const [searchField, setSearchField] = useState("");

  if (columns.length > 0 && !searchField) {
    setSearchField(columns[0].field);
  }

  const filteredItems = useMemo(() => {
    if (!searchField) return data;
    return data.filter((item) => {
      const itemValue = item[searchField] ? item[searchField].toString().toLowerCase() : "";
      return itemValue.includes(filterText.toLowerCase());
    });
  }, [data, filterText, searchField]);

  const [toggledClearRows, setToggleClearRows] = useState(false);

  // Toggle the state so React Data Table changes to clearSelectedRows are triggered
  const handleClearRows = () => {
    setToggleClearRows(!toggledClearRows);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <Select
              labelId="search-field-label"
              id="search-field"
              value={searchField}
              label="Search Field"
              onChange={(e) => setSearchField(e.target.value)}
            >
              {columns.map((col, index) => (
                <MenuItem key={index} value={col.field}>
                  {col.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            id="search"
            type="text"
            placeholder="Search"
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
          />
        </Grid>
      </Grid>

      <DataTable
        columns={columns}
        data={filteredItems}
        selectableRows
        selectableRowsComponent={Checkbox}
        selectableRowsComponentProps={selectProps}
        onSelectedRowsChange={onSelection}
        clearSelectedRows={handleClearRows}
        pagination
        paginationComponentOptions={{
          selectAllRowsItem: true,
        }}
      />
    </>
  );
};

export default DataTableAdvanced;
