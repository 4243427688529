import React, { useMemo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DataTable from "react-data-table-component";
import {
  Box,
  Button,
  TextField,
  Grid,
  InputAdornment,
  Dialog,
  Switch,
  FormGroup,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import CloseIcon from "@mui/icons-material/Close";
import { doPrint, exportToXlsx } from "../../functions";
import LinearProgress from "@mui/material/LinearProgress";
import { Checkbox, FormControlLabel } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import moment from "moment";
import "moment/locale/hu";
import { useHost } from "../../context/HostContext";
import { searchTransaction, updateUser } from "../../services/apiService";
//import { useSSE } from "../../services/sseService";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import {  QrCodeScanner, QrCode, FileDownload } from "@mui/icons-material";
import QRScanner from "../qrscanner/QRScanner";
import UserForm from "./UserForm";
import { useMessage } from "../../context/MessageContext";



//const searchableColumns = columns.filter((column) => !column.omit).map((column) => column.field);
const searchableColumns = ["user_name"];

const sortIcon = <ArrowDownward />;

const SearchTransactionForm = () => {
  const { t } = useTranslation();

  const { host } = useHost();

  const { setMessage } = useMessage();

  const [data, setData] = useState([]);

  const initialState = {
    user_enabled: "indeterminate", // 'unchecked', 'checked', 'indeterminate'
    user_qrcode_printed: "indeterminate", // 'unchecked', 'checked', 'indeterminate'
  };

  const [filterText, setFilterText] = useState("");
  const [filterQRCode, setFilterQRCode] = useState("");
  const [filterStatus, setFilterStatus] = useState(initialState);

  const [isLoading, setIsLoading] = useState(null);
  const [isUpdating, setIsUpdating] = useState([]);

  const [selectedRows, setSelectedRows] = React.useState([]);
  const [toggleCleared, setToggleCleared] = React.useState(false);

  const [open, setOpen] = useState(false);
  const [openQRCode, setOpenQRCode] = useState(false);
  const [openQRCodeScanner, setOpenQRCodeScanner] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  /*
  const [dataChanged, setDataChanged] = useState({});

  useSSE(setDataChanged, "/table/users");

  useEffect(() => {
    console.log(dataChanged);
  }, [dataChanged]);
*/
  const removeUpdateOperation = (userId, fieldName) => {
    setIsUpdating((prev) => prev.filter((op) => op.userId !== userId || op.fieldName !== fieldName));
  };

  const isFieldUpdating = (userId, fieldName) => {
    return isUpdating.some((op) => op.userId === userId && op.fieldName === fieldName);
  };

  const handleCellClicked = (row, fieldName) => {
    setIsUpdating((prev) => [...prev, { userId: row.user_id, fieldName }]);

    switch (fieldName) {
      case "user_enabled":
        updateUser(host.id, row.user_id, { [fieldName]: !row[fieldName] })
          .then((response) => {
            setData((currentData) =>
              currentData.map((item) => (item.user_id === response.user_id ? { ...item, ...response } : item))
            );
          })
          .catch((error) => console.log(error))
          .finally(() => removeUpdateOperation(row.user_id, fieldName));
        break;
      case "user_qrcode":
        updateUser(host.id, row.user_id, { user_qrcode: "" })
          .then((response) => {
            setData((currentData) =>
              currentData.map((item) => (item.user_id === response.user_id ? { ...item, ...response } : item))
            );
          })

          .catch((error) => {
            console.log(error);
            // Handle any errors that occurred during the printing or updating process
          })
          .finally(() => {
            removeUpdateOperation(row.user_id, fieldName);
            // Perform any cleanup operations here, like removing the item from a list of updating items
          });
        break;
      case "user_qrcode_printed":
        if (row.user_qrcode_printed) {
          removeUpdateOperation(row.user_id, fieldName);

          setIsUpdating((prev) => [...prev, { userId: row.user_id, fieldName: "user_qrcode" }]);

          setOpenQRCode({ row, fieldName: "user_qrcode" });
        } else {
          doPrint(row["user_qrcode"], row["user_first_name"], row["user_last_name"], undefined, {...host?.event_config})
            .then((response) => {
              if (!response?.result) {
                throw new Error("Printing failed");
              }

              return updateUser(host.id, row.user_id, { user_qrcode_printed: new Date() });
            })
            .then((response) => {
              setData((currentData) =>
                currentData.map((item) => (item.user_id === response.user_id ? { ...item, ...response } : item))
              );
            })
            .catch((error) => {
              setMessage(error);
              setTimeout(() => setMessage(""), 5 * 1000);
            })
            .finally(() => {
              removeUpdateOperation(row.user_id, fieldName);
            });
        }

        /*    EZ NEM TUDOM MIRE VAN //TODO       
        confirm kell
        updateUser(host.id, row.user_id, { user_qrcode: "" })
          .then((response) => {
            if (response[fieldName] === !row[fieldName]) {
                    setData((currentData) =>
              currentData.map((item) => (item.user_id === response.user_id ? { ...item, ...response } : item))
            )
            }
          })
          .catch((error) => console.log(error))
          .finally(() => removeUpdateOperation(row.user_id, fieldName));
 */
        break;

      default:
        break;
    }
  };

  const columns = [
    {
      id: "updatedAt",
      name: "Módosítva",
      sortable: true,
      field: "updatedAt",
      selector: (row) => (row["updatedAt"] ? moment(row["updatedAt"]).locale("hu").format("L HH:mm:ss:SSS") : ""),
    },
    {
      id: "user_name",
      name: "Név",
      sortable: true,
      field: "user_name",
      selector: (row) => row["User"]["user_name"],
    },
    {
      id: "resource_name",
      name: "Erőforrás",
      sortable: true,
      field: "resource_name",
      selector: (row) => row["Resource"]["resource_name"],
    },
    {
      id: "resource_transaction_quantity",
      name: "Mennyiség változás",
      field: "resource_transaction_quantity",
      selector: (row) => row["resource_transaction_quantity"],
    },
    {
      id: "resource_transaction_description",
      name: "Megjegyzés",
      field: "resource_transaction_description",
      selector: (row) => row["resource_transaction_description"],
    },
    {
      id: "handler_name",
      name: "Rendezvénykoordinátor",
      sortable: true,
      field: "handler_name",
      selector: (row) => row["Handler"]["user_name"],
    },
  ];

  function fetchData() {
    setIsLoading(true);

    searchTransaction(
      {  },
      { resourcedata: true, userdata: true, handlerdata: true }
    )
      .then((response) => {
        setData(response);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setIsLoading(false));
  }

  useEffect(() => {
    if (!isLoading && data.length === 0) {
      setIsLoading(true);

      fetchData();
    }
  }, [isLoading, data]);

  useEffect(() => {
    setToggleCleared(!toggleCleared);
  }, [filterText]);

  const filteredItems = useMemo(() => {
    return data.filter((item) => {
      const textMatch = searchableColumns.some((field) => {
        let itemValue;
        const column = columns.find((column) => column.field === field);
        if (column && column.format) {
          itemValue = column.format(item).toString().toLowerCase();
        } else {
          itemValue = item[field] ? item[field].toString().toLowerCase() : "";
        }

        return itemValue.includes(filterText.toLowerCase());
      });

      const qrcodeMatch = ["user_qrcode"].some((field) => {
        let itemValue;
        const column = columns.find((column) => column.field === field);
        if (column && column.format) {
          itemValue = column.format(item).toString().toLowerCase();
        } else {
          itemValue = item[field] ? item[field].toString() : "";
        }

        return itemValue.includes(filterQRCode);
      });

      const statusMatch = Object.keys(filterStatus).every((field) => {
        const status = filterStatus[field];
        if (status === "indeterminate") {
          return true; // Do not filter out if status is indeterminate
        }

        const fieldValue = item[field];
        if (status === "checked" && fieldValue) {
          return true;
        }
        if (status === "unchecked" && !fieldValue) {
          return true;
        }

        return false;
      });

      return textMatch && qrcodeMatch && statusMatch;
    });
  }, [data, filterText, filterQRCode, filterStatus, searchableColumns]);

  
  const flattenData = (source) => {
    let flattened = [];

    source.forEach((user) => {
      // Basic user information without roles and resources
      let userInfo = {
        _updatedAt_: moment(user.updatedAt).locale("hu").format("L HH:mm:ss:SSS"),
        _user_name_: user.User.user_name,
        _resource_name_: user.Resource.resource_name,
        _resource_transaction_quantity_: user.resource_transaction_quantity,
        _resource_transaction_description_: user.resource_transaction_description,
        _handler_name_: user.Handler.user_name,
      };

    

      flattened.push(userInfo);
    });

    return flattened.map((item) => {
      const newItem = {};
      // For each key in the item, find the corresponding column and use its 'name' as the new key
      Object.keys(item).forEach((key) => {
        const column = columns.find((c) => key.includes("_" + c.field + "_"));
        const newKey = column ? key.replace("_" + column.field + "_", column.name) : key;
        newItem[newKey] = item[key];
      });
      return newItem;
    });
  };

  const contextActions = React.useMemo(() => {
    return (
      <>
        <Button
          variant="contained"
          startIcon={<FileDownload className={isFieldUpdating(0, "export") ? "flip-horizontal" : ""} />}
          disabled={isFieldUpdating(0, "export")}
          sx={{
            minWidth: 0, // Minimize button width
            padding: "6px 0 6px 10px", // Adjust padding as needed
            margin: "10px",
          }}
          onClick={() => {
            setIsUpdating((prev) => [...prev, { userId: 0, fieldName: "export" }]);
            exportToXlsx(flattenData(selectedRows));
            removeUpdateOperation(0, "export");

            setToggleCleared(!toggleCleared);
          }}
        >
          <span className="hide-on-mobile">Export</span>
        </Button>
        <FormGroup sx={{ display: "none" }}>
          <FormControlLabel control={<Switch defaultChecked color="primary" />} label="Gyors" />
        </FormGroup>
        <Button
          variant="contained"
          startIcon={<QrCode className={isFieldUpdating(0, "print") ? "flip-horizontal" : ""} />}
          disabled={isFieldUpdating(0, "print")}
          sx={{
            minWidth: 0, // Minimize button width
            padding: "6px 0 6px 10px", // Adjust padding as needed
            margin: "10px",
            "& .MuiButton-label": {
              fontSize: { xs: 0, sm: "1rem" },
            },
          }}
          onClick={() => setOpen(true)}
        >
          <span className="hide-on-mobile">QR-kód nyomtatás</span>
        </Button>
      </>
    );
  }, [data, selectedRows]);

  const handleConfirmChange = () => {
    setOpen(false);

    const printList = selectedRows.map((row) => ({
      objTopText: row.user_last_name,
      objBottomText: row.user_first_name,
      objQRCode: row.user_qrcode,
    }));

    doPrint("", "", "", printList, {...host?.event_config})
      .then((response) => {
        if (!response?.result) {
          throw new Error("Printing failed");
        }

        selectedRows.forEach((row) => {
          updateUser(host.id, row.user_id, { user_qrcode_printed: new Date() })
            .then((response) => {
              setData((currentData) =>
                currentData.map((item) => (item.user_id === response.user_id ? { ...item, ...response } : item))
              );
            })
            .catch((error) => {
              console.log(error);
              // Handle any errors that occurred during the printing or updating process
            });
        });
        setToggleCleared(!toggleCleared);
      })
      .catch((error) => {
        setMessage(error);
        setTimeout(() => setMessage(""), 5 * 1000);
      });
  };

  const handleFilterChange = (event) => {
    setToggleCleared(!toggleCleared);
    const { name } = event.target;

    setFilterStatus((prev) => {
      const nextState =
        prev[name] === "unchecked" ? "checked" : prev[name] === "checked" ? "indeterminate" : "unchecked";

      return { ...prev, [name]: nextState };
    });
  };

  const handleRowSelected = React.useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const paginationComponentOptions = {
    rowsPerPageText: "",
    rangeSeparatorText: "/",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Összes",
  };

  const LinearIndeterminate = () => {
    return (
      <Box
        sx={{
          width: "100%",
          "& > * + *": {
            marginTop: 2,
          },
        }}
      >
        <LinearProgress />
      </Box>
    );
  };


  return (
    <>
      <Grid
        container
        gap={1}
        justifyContent={"space-around"}
        alignContent={"space-around"}
        alignItems={"center"}
        marginTop={1}
      >
        <Button
          variant="contained"
          startIcon={<RefreshIcon className={isLoading ? "rotate" : ""} />} // Replace "favorite" with your icon name
          sx={{
            padding: "6px", // Adjust padding as needed
            "& .MuiButton-startIcon": {
              margin: 0, // Remove margin around the icon for a compact look
            },
          }}
          onClick={() => {
            setToggleCleared(!toggleCleared);
            fetchData();
          }}
        >
          Frissítés
        </Button>
        <Grid container flexDirection={"column"} width={"fit-content"} sx={{ padding: 0 }}>
          <FormControlLabel
            control={
              <Checkbox
                color={filterStatus["user_enabled"] !== "indeterminate" ? "secondary" : "primary"}
                name="user_enabled"
                checked={filterStatus["user_enabled"] === "checked"}
                indeterminate={filterStatus["user_enabled"] === "indeterminate"}
                onChange={handleFilterChange}
              />
            }
            label="Engedélyezett"
          />
          <FormControlLabel
            control={
              <Checkbox
                color={filterStatus["user_qrcode_printed"] !== "indeterminate" ? "secondary" : "primary"}
                name="user_qrcode_printed"
                checked={filterStatus["user_qrcode_printed"] === "checked"}
                indeterminate={filterStatus["user_qrcode_printed"] === "indeterminate"}
                onChange={handleFilterChange}
              />
            }
            label="Nyomtatva"
          />
        </Grid>

        <TextField
          color={filterText ? "secondary" : "primary"}
          id="search"
          type="text"
          placeholder="Keresés..."
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
          InputProps={{
            endAdornment: filterText && (
              <InputAdornment position="end">
                <IconButton onClick={() => setFilterText("")} edge="end" size="small" sx={{ color: "red" }}>
                  <CloseIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{
            flexGrow: 1,
            maxWidth: 250,
            "& .MuiInputAdornment-root .MuiIconButton-root": {
              color: "red", // This styles the IconButton color
            },
          }}
        />
        <Button
          color={filterQRCode ? "secondary" : "primary"}
          variant="contained"
          startIcon={<QrCodeScanner />}
          sx={{
            minWidth: 0, // Minimize button width
            padding: "6px", // Adjust padding as needed
            "& .MuiButton-startIcon": {
              margin: 0, // Remove margin around the icon for a compact look
            },
          }}
          onClick={() => (filterQRCode ? setFilterQRCode("") : setOpenQRCodeScanner(true))}
        >
          Keresés
        </Button>
      </Grid>

      <DataTable
        columns={columns}
        data={filteredItems}
        progressPending={isLoading}
        progressComponent={<LinearIndeterminate />}
        sortIcon={sortIcon}
        noDataComponent={t("nodata")}
        contextMessage={{
          singular: "elem",
          plural: "elem",
          message: "kiválasztva",
        }}
        pagination
        paginationRowsPerPageOptions={[10, 50, 100, 500]}
        paginationComponentOptions={paginationComponentOptions}
        selectableRows
        selectableRowsComponent={Checkbox}
        actions
        contextActions={contextActions}
        onSelectedRowsChange={handleRowSelected}
        clearSelectedRows={toggleCleared}
        selectableRowsVisibleOnly
      />

      <ConfirmationDialog
        open={open}
        handleCancelChange={() => setOpen(false)}
        handleConfirmChange={handleConfirmChange}
        titleText={t("Biztosan elindítja nyomtatást?")}
      />

      <ConfirmationDialog
        open={openQRCode}
        handleCancelChange={() => {
          removeUpdateOperation(openQRCode["row"].user_id, openQRCode["fieldName"]);

          setOpenQRCode(false);
        }}
        handleConfirmChange={() => {
          setOpenQRCode(false);

          handleCellClicked(openQRCode["row"], openQRCode["fieldName"]);
        }}
        titleText={t("Új QR-kód generálás indulhat?")}
        descriptionText={t("FIGYELEM! A régi QR-kód érvénytelenné válik. Ne felejtse el kinyomtatni az új QR-kódot!")}
      />

      <Dialog open={openQRCodeScanner} onClose={() => setOpenQRCodeScanner(false)}>
        <QRScanner
          setResult={(result) => {
            setOpenQRCodeScanner(false);
            setFilterQRCode(result);
          }}
        />
      </Dialog>

      <Dialog open={openEdit} onClose={() => setOpenEdit(false)}>
        <UserForm
          onSubmit={(formData) => {
            setOpenEdit(false);

            const fieldName = "user_name";
            setIsUpdating((prev) => [...prev, { userId: formData.user_id, fieldName }]);

            const { user_id, ...fields } = formData;

            fields["user_name"] = `${formData.user_title || ""} ${formData.user_last_name} ${
              formData.user_first_name
            }`.trim();

            updateUser(host.id, user_id, fields)
              .then((response) => {
                setData((currentData) =>
                  currentData.map((item) => (item.user_id === response.user_id ? { ...item, ...response } : item))
                );
              })
              .catch((error) => console.log(error))
              .finally(() => removeUpdateOperation(formData.user_id, fieldName));
          }}
          initialFormData={openEdit}
        />
      </Dialog>
    </>
  );
};

export default SearchTransactionForm;
